
import React, { useEffect } from 'react'
import Slider from 'react-slick';
import Aos from 'aos';
import { Link } from 'react-router-dom';
export default function Portfolio() {
    document.title =" Telling Stories With Brand Videos I Creating Visuals With Focus"; 
	document.getElementsByTagName("META")[2].content=" Adding aesthetics into the brainstormed ideas. Walk through the secrets of a perfect brand video with our video production services. Click to know our secrets. "; 
    useEffect(() => {
        if((window.jQuery(window).width() < 767)){
            window.jQuery('.res_slider').slick({
                dots:true,
            });
            window.jQuery('.res_slider2').slick();
        }
        
        Aos.init()
    })
  return (
    <>
        <section className='portfolio_sec1 videos'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <h1 className='banner_h1 text-center'>Portfolio </h1>
                        <p className='defaultp text-center'>From whiteboard animation services to animated logo design, we've done so much more beyond explainer videos. Check it out!</p>
                        <div className='h_btn justify-content-center'>
                            <Link data-aos="fade-up" data-aos-duration="8600" className='pop-btn white_btn' to={"contact"}>get a quote</Link>
                            <a href='javascript:void(Tawk_API.toggle())' className='pop-btn black_btn chats' data-aos="fade-up" data-aos-duration="900">Live Chat</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='portfolio_tab videos'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                       
                    </div>
                </div>
            </div>
        </section>
    </>

  )
}
