import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { LightgalleryItem } from "react-lightgallery";
import Slider from "react-slick";
import CtaSec from "../components/includes/CtaSec";
import { Accordion } from "react-bootstrap";
import Popup from "../components/includes/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import sec1 from "../components/assets/img/1.png";

export default function ExplainerVideos() {
  document.title = "Professional Explainer Videos | Boost Your Engagement";
  // document.getElementsByTagName("META")[2].content =
  //   "Engage your audience with our explainer videos. We craft animation that turn complex ideas in a simplified way. Leave a lasting impression with us.";
  var Portfolio_Slider = {
    slidesToShow: 1,
    infinite: true,
    dots: true,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "120px",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "50px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    window.jQuery(document).ready(function () {
      window.jQuery(".myBtn").click(function () {
        var dots = window.jQuery(".dots");
        var moreText = window.jQuery(".more");
        var btnText = window.jQuery(".myBtn");

        if (dots.css("display") === "none") {
          dots.css("display", "inline");
          btnText.html("Read more");
          moreText.css("display", "none");
        } else {
          dots.css("display", "none");
          btnText.html("Read less");
          moreText.css("display", "inline");
        }
      });
    });
    if (window.jQuery(window).width() < 767) {
      window.jQuery(".res_slider").slick({
        dots: true,
      });
      window.jQuery(".res_slider2").slick();
    }

    Aos.init();
  });
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.videojeeves.com/explainer-videos"
        />
        <meta name="description" content="Engage your audience with our explainer videos. We craft animation that turn complex ideas in a simplified way. Leave a lasting impression with us." />
      </Helmet>
      <section className="inner_sec1 videos">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h1 className="banner_h1">
                Explainer Videos Leave a Lasting Impact
              </h1>
              <p className="defaultp">No more bland video content!</p>
              <p className="defaultp">
                There’s a reason why successful businesses prefer visual content
                over plain text; it’s because people grasp visuals 60,000 times
                faster. (Acc. to HubSpot)
              </p>
              <p className="defaultp">
                Animated explainer videos are perfect for showing highlights,
                portraying a brand story, or simplifying a complex idea.
              </p>
              <div className="h_btn">
                <Link
                  data-aos="fade-up"
                  data-aos-duration="8600"
                  className="popup-btn white_btn"
                  to="/Contact-us"
                >
                  get a quote
                </Link>
                <a
                  href="javascript:void(Tawk_API.toggle())"
                  className="pop-btn black_btn chats"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Live Chat
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 banner_play">
              <LightgalleryItem src="https://youtu.be/rtlIYKgNd4w">
                <img className="play-btn-st" src="images/play.png" alt="" />
              </LightgalleryItem>
              <img
                className="img-fluid"
                src="images/explainervideos-page/s1right.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                alt=""
                className="img-fluid"
                src="images/explainervideos-page/1.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                Explainer Video Simplifies Complex Ideas
              </h2>
              <p className="defaultp mb-3">
                Life is too short to go through the manuals! So, how about short
                animated pieces that simplify ideas for your audience?
              </p>
              <p className="defaultp mb-3">
                Turn intangible ideas into something visually presentable
                through video explainers. These animated marvels are like a
                symphony for the senses—a fusion of art and knowledge that will
                leave your audience amazed for more.
              </p>
              <p className="defaultp mb-3">
                Let us take you to a realm where even the most complex ideas are
                transformed into bite-sized, animated wonders.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec3 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                Not a Traditional Explainer Video Company
              </h2>
              <p className="defaultp mb-3">
                <b>Let's make boring explanations illegal!</b>
              </p>
              <p className="defaultp mb-3">
                Unlike other explainer video companies, Video Jeeves crafts
                unique, yet fun, and easy-to-watch videos. Our animated
                explainer videos show viewers why you are awesome and how you
                add value to their lives.
              </p>
              <p className="defaultp mb-3">
                Explainer videos are a perfect way to bring new customers on
                board and demonstrate your expertise.
              </p>
              <p className="defaultp mb-3">
                Our explainer video makers are artsy yet proficient, and create
                visuals that stick with the audiences!{" "}
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                className="img-fluid"
                src="images/explainervideos-page/2.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="m_sec4 inner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">Going Beyond Automation </h2>
              <p className="defaultp">
                Offering an alignment where clarity and chuckles meet.
              </p>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/zg3NzXulWL8?si=JVbcs5YxxMCRsrMJ">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  {/* <LightgalleryItem></LightgalleryItem> */}

                  <img
                    className="img-fluid"
                    src="images/explainervideos-page/s4img1.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>RSVP Photobooth</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=CYm5nW-TB30&t=1s">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/explainervideos-page/s4img2.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>SMC Panduit</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/_yXbRAcaqPo?si=hp4QDkUiJzCKhBnk">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    className="img-fluid"
                    src="images/explainervideos-page/s4img3.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Snowbird Investor</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=TRfsOG-7t_0">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    className="img-fluid"
                    src="images/explainervideos-page/s4img4.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Venture Capital</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/qgSPyEdZMhU">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    className="img-fluid"
                    src="images/explainervideos-page/s4img5.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Voipfone</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=lqCRl2Ki-WE">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    className="img-fluid"
                    src="images/explainervideos-page/s4img6.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Yash Solutions</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                className="img-fluid"
                src="images/explainervideos-page/3.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                We are a Pro at Strong Creative Direction
              </h2>
              <p className="defaultp mb-3">
                Our artistic squad will narrate your tale to the world.
              </p>
              <p className="defaultp mb-3">
                It is necessary to ensure that the storyboard and voice-over
                both come along really well. Our illustrators draw inspiration
                through the script and visuals to offer the best explainer video
                services.
              </p>
              <p className="defaultp mb-3">
                Once the animations are completed, we handle all the technical
                stuff for video analytics.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='inner_sec3 zig_zag'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <h2 className='defaulth2'>Exploring Real-World</h2>
                    <p className='defaultp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit tortor sit amet lectus luctus auctor. Quisque hendrerit pellentesque urna, eu eleifend dolor ornare ac. Nullam ornare tincidunt erat, ut laoreet purus condimentum ut. Fusce feugiat ipsum nisi, sit amet commodo odio imperdiet eu. Mauris luctus consequat arcu quis vestibulum. Aenean laoreet, dui eu rhoncus placerat, dolor turpis euismod lorem, quis eleifend tortor eros id diam. Morbi a tincidunt ex. Cras augue dolor, molestie ut dapibus id, interdum vel felis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit tortor sit amet lectus luctus auctor. Quisque hendrerit pellentesque urna, eu eleifend dolor ornare ac. Nullam ornare tincidunt erat, ut laoreet purus condimentum ut. Fusce feugiat ipsum nisi, sit amet commodo odio imperdiet eu. Mauris luctus consequat arcu quis vestibulum. Aenean laoreet, dui eu rhoncus placerat, dolor turpis euismod lorem, quis eleifend tortor eros id diam. Morbi a tincidunt ex. Cras augue dolor, molestie ut dapibus id, interdum vel felis.</p>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <img className='img-fluid' src='images/innerservice-page/s3right.png'></img>
                </div>
            </div>
        </div>
    </section> */}
      <section className="m_sec2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">Stirring innovation into ideas</h2>
              <p className="defaultp mb-3">
                Step into our world, where imagination takes center stage!
              </p>
              <p className="defaultp">
                We're the modern-day wizards coming up with animated wonders.
                Our creative team blends ideas, stirs innovation, and voila! Now
                your vision has transformed into a reality!
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process">
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Concept & Story Creation</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Scriptwriting</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Storyboarding</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Style & Visual design</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Voiceover & Sound design</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process_gif res_slider2">
                <li className="">
                  <img src="images/mainservice-page/process_1.gif"></img>
                </li>
                <li className="">
                  <img src="images/mainservice-page/process_2.gif"></img>
                </li>
                <li className="">
                  <img src="images/mainservice-page/process_3.gif"></img>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_portfolio">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h2 className="defaulth2">Portfolio</h2>
              <p className="defaultp">
                Video Jeeves is a 2D explainer video company that delivers
                quality services for your business. Take a look at our
                portfolio!
              </p>
              <Link className="popup-btn white_btn button-86" to="/Contact-us">
                Get started
              </Link>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="portfolio_slider">
                <Slider {...Portfolio_Slider}>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://youtu.be/AO5Gnv6ZyNg?si=pS88T4h6BhNep8r9">
                      <img
                        className="play-btn-st"
                        src="images/play.png"
                        alt=""
                      />
                    </LightgalleryItem>
                    <img
                      className="img-fluid"
                      src="images/explainervideos-page/port_img1.png"
                    ></img>
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://youtu.be/k_FtN7w-neE?si=ZCRJqzis62i1fw5r">
                      <img
                        className="play-btn-st"
                        src="images/play.png"
                        alt=""
                      />
                    </LightgalleryItem>
                    <img
                      className="img-fluid"
                      src="images/explainervideos-page/port_img2.png"
                    ></img>
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://www.youtube.com/watch?v=TRfsOG-7t_0">
                      <img
                        className="play-btn-st"
                        src="images/play.png"
                        alt=""
                      />
                    </LightgalleryItem>
                    <img
                      className="img-fluid"
                      src="images/explainervideos-page/port_img3.png"
                    ></img>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec2 inner_serv">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="h_s2_content">
                <h2
                  className="defaulth2"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  Explore Our Services
                </h2>
                <p className="defaultp">We have done so much more. </p>
              </div>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <LightgalleryItem src="https://www.youtube.com/watch?v=semVzZE8AHw">
                  {/* <video  className='img-fluid'  src="images/explainervideos-page/other_serv1.png" muted autoPlay={"autoplay"} preload="auto" loop ></video> */}
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  className="img-fluid"
                  src="images/explainervideos-page/compliance.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    {" "}
                    <Link to="/motion-graphics-videos">
                      {" "}
                      Explore Motion Graphics
                    </Link>{" "}
                  </h5>
                  {/* <p>Social media videos connect with what you do and what you stand for in a compelling and authentic way.</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1100"
              >
                <LightgalleryItem src="https://youtu.be/m-uzJinTqgo?si=7_LFMDqPk8tpFk05">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  className="img-fluid"
                  src="images/explainervideos-page/orisis.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/3d-videos"> Explore 3D Animation</Link>{" "}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <LightgalleryItem src="https://youtu.be/ayb5RVhiDLU?si=rYHr6motuNZuPeVt">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  className="img-fluid"
                  src="images/explainervideos-page/whiteboard.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/white-board"> Explore Whiteboard Videos</Link>{" "}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec7">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2
                className="defaulth2"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                FAQs
              </h2>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    What is an explainer video, and how can it benefit my
                    business?
                  </Accordion.Header>
                  <Accordion.Body>
                    An explainer video is a short, engaging video that explains
                    a concept clearly and concisely. It can benefit your
                    business by simplifying complex ideas, increasing conversion
                    rates, and improving engagement with your audience.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    How can I ensure that my video aligns with my brand's
                    identity?
                  </Accordion.Header>
                  <Accordion.Body>
                    Our team works closely with you to understand your brand's
                    identity and message. This includes using your brand colors,
                    logo, and tone to ensure the video aligns with your brand
                    identity and drives your concept home.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    What is the distribution process for my explainer video?
                  </Accordion.Header>
                  <Accordion.Body>
                    We can assist you in the distribution process by providing
                    guidance on platforms, marketing strategies, and best
                    practices for reaching your target audience.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Can I use my explainer video on multiple platforms?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, explainer videos are versatile and can be used on
                    various platforms. We can provide different formats and
                    versions according to your needs.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <CtaSec></CtaSec>
    </>
  );
}
