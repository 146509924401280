import React, { useEffect, useState } from "react";
import TestiSec from "../components/includes/TestiSec";
import FaqSec from "../components/includes/FaqSec";
import CtaSec from "../components/includes/CtaSec";
import Calendar from "react-calendar";
import { Link } from "react-router-dom";
import MeetingBtn from "../components/includes/MeetingBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

export default function Contact() {
  const [value, onChange] = useState(new Date());

  useEffect(() => {
    window.jQuery(".txtOnly").keypress(function (e) {
      var key = e.keyCode;
      if (key >= 48 && key <= 57) {
        e.preventDefault();
      }
    });
    if (window.jQuery(window).width() < 767) {
      window.jQuery(".res_slider").slick({
        dots: false,
        infinite: true,
        arrows: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
      });
    }
  });
  return (
    <>
    <Helmet>
    <link rel="canonical" href="https://www.videojeeves.com/contact-us"/>
</Helmet>
      <section className="c_sec1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <h2 className="defaulth2">Hi There!</h2>
              <p className="defaultp">
                We're a top Video Agency that creates unique animated videos
                connecting brands and people. From small startups to big
                companies, we serve everyone.
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="c_banner_form">
                <form action="https://www.videojeeves.com/mail/" method="POST">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control txtOnly"
                            name="cn"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Your Name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            className="form-control"
                            onInput={(e) => {
                              if (e.target.value.length > e.target.maxLength)
                                e.target.value = e.target.value.slice(
                                  0,
                                  e.target.maxLength
                                );
                            }}
                            type="number"
                            maxLength={12}
                            name="pn"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Phone Number"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            name="em"
                            id=""
                            aria-describedby="helpId"
                            placeholder="Email address"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <textarea
                            className="form-control msg"
                            name="msg"
                            placeholder="How can we help? details please!"
                            required
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <div
                            className="g-recaptcha"
                            data-sitekey="6Lf9qQgpAAAAAAoGLP3xMyET9tW33HovbXXd9oac"
                            data-size="invisible"
                          ></div>
                          <button type="submit" className="">
                            Submit
                            <input
                              type="hidden"
                              id="lead_area_popup"
                              name="lead_area"
                              value="for $119"
                            />
                            <input
                              type="hidden"
                              id="lead_org_price"
                              name="lead_org_price"
                              value="19"
                            />
                            <input type="hidden" name="send" value="1" />
                            <input
                              type="hidden"
                              name="service_id"
                              value="5791"
                              className="service_id"
                            />
                            <input type="hidden" name="lb_source" value="" />
                            <input
                              type="hidden"
                              name="lb_source_cat"
                              value=""
                            />
                            <input
                              type="hidden"
                              name="lb_source_nam"
                              value=""
                            />
                            <input
                              type="hidden"
                              name="lb_source_ema"
                              value=""
                            />
                            <input
                              type="hidden"
                              name="lb_source_con"
                              value=""
                            />
                            <input
                              type="hidden"
                              name="lb_source_pho"
                              value=""
                            />
                            <input
                              type="hidden"
                              name="lb_source_off"
                              value=""
                            />
                            <input type="hidden" name="fullpageurl" value="" />
                            <input type="hidden" name="pageurl" value="" />
                            <input type="hidden" name="ip2loc_ip" value="" />
                            <input type="hidden" name="ip2loc_isp" value="" />
                            <input type="hidden" name="ip2loc_org" value="" />
                            <input
                              type="hidden"
                              name="ip2loc_country"
                              value=""
                            />
                            <input
                              type="hidden"
                              name="ip2loc_region"
                              value=""
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TestiSec></TestiSec>
      {/*      <section className="c_sec2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="c_s2_left">
                <h2 className="defaulth2">Why Do Clients Love Us?</h2>
                <p className="defaultp">
                  Because making clients 'love us' is our favorite success story
                  to tell
                </p>
                <ul className="list-unstyled">
                  <li>
                    <i className="fa fa-check" aria-hidden="true"></i>Get in touch
                    here
                  </li>
                  <li>
                    <i className="fa fa-check" aria-hidden="true"></i> Extensive
                    Experience
                  </li>
                  <li>
                    <i className="fa fa-check" aria-hidden="true"></i> Unlimited
                    Revisions
                  </li>
                  <li>
                    <i className="fa fa-check" aria-hidden="true"></i> Smooth
                    Process
                  </li>
                  <li>
                    <i className="fa fa-check" aria-hidden="true"></i> Quality Check
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12">
           
            <div className="c_s2_right">
                <div className="calender_left">
                  <div className="data">
                    <img
                      className="img-fluid"
                      src="images/about-page/calender_guy.png"
                    ></img>
                    <p className="meet text-light">Meet with</p>
                    <p className="name text-light">Mattew Stirbe</p>
                  </div>
                  <a
                    href="https://meetings.hubspot.com/athar-g"
                    className="chats"
                  >
                    Start Live Chat
                  </a>
                  <p className="text-light">or</p>
                  <a href="tel:(650)392-3384" className="call">
                    Call (650) 392-3384
                  </a>
                </div>
                <div>
                  <Calendar onChange={onChange} value={value} />
                </div>
              </div>
              </div>
              </div>
              </div>
              </section>
            */}
      <section className="c_sec3">
        <div className="container">
          <div className="row res_slider">
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div className="c_s3_box">
                <h4>Contact Us</h4>
                <a href="tel:(650)392-3384">
                  <FontAwesomeIcon icon={faPhone} /> (650) 392-3384
                </a>
                <a href="mailto:info@videojeeves.com">
                  <FontAwesomeIcon icon={faEnvelope} /> info@videojeeves.com
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div className="c_s3_box">
                <h5>USA</h5>
                <p className="defaultp">
                  <FontAwesomeIcon icon={faLocationDot} /> Fremont Blvd #5,
                  Fremont, CA 94538, United States
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div className="c_s3_box">
                <h5>DUBAI</h5>
                <p className="defaultp">
                  <FontAwesomeIcon icon={faLocationDot} /> 1904, The Metropolis
                  Tower, Business Bay, Dubai
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div className="c_s3_box">
                <h5>UK</h5>
                <p className="defaultp">
                  <FontAwesomeIcon icon={faLocationDot} /> Milton Keynes Office
                  6, Brinklow, Milton Keynes, England, MK10 0BB
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FaqSec></FaqSec>
      <CtaSec></CtaSec>
    </>
  );
}
