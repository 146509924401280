import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import AOS from "aos";

export default function FaqSec() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });
  return (
    <>
      <section className="h_sec7">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2
                className="defaulth2"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                FAQs
              </h2>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    How is Video Jeeves different?{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    We take the time it requires to understand your brand and
                    vision. Before initiating any project, we ensure that we are
                    on the same page. Most video production companies don’t put
                    in the effort to understand your ideas, and that’s what
                    separates us from them.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    What services do you offer?
                  </Accordion.Header>
                  <Accordion.Body>
                    We offer diverse services and solutions, including 3D
                    Animation, 2D Animation, Motion Graphics, Whiteboard
                    Animation, Video Animation, Cartoon Animation, Explainer
                    Video Animation, Educational Videos, Training Videos, Social
                    Media Ads, Branded Videos, Live-Action Videos, and pretty
                    much anything you can think of.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    How long does it take to create a minute-long video?
                  </Accordion.Header>
                  <Accordion.Body>
                    It depends if the video is in 2D or 3D. Typically, it takes
                    around 2 weeks to produce a one-minute 2D video. However,
                    the timeline for both 2D & 3D varies depending on multiple
                    factors. If you want to get an estimate, feel free to ask
                    over chat or email us.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    How much information will you need?{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    The more information you give us about your brand, the
                    better. Tell us the tone you prefer, your target audience,
                    your goals for a video campaign, or anything else that can
                    help us decide the best course of action.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    What type of video will be best suited for my business?
                  </Accordion.Header>
                  <Accordion.Body>
                    The answer to this question lies within your business and
                    your target audience. We offer a variety of services for you
                    to pick from based on your preferences. We can also help you
                    come to that decision by discussing your goals. Feel free to
                    discuss your ideas and vision. Email us at{" "}
                    <a href="mailto:info@videojeeves.com ">
                      info@videojeeves.com
                    </a>{" "}
                    or connect over live chat.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
