import React, { useEffect } from "react";
import Slider from "react-slick";
import { LightgalleryItem } from "react-lightgallery";
import { Accordion } from "react-bootstrap";
import AOS from "aos";

export default function () {
  var Testi_Slider = {
    slidesToShow: 2,
    infinite: true,
    dots: true,
    arrows: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });
  return (
    <>
      <section className="h_sec3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h3
                className="defaulth3"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                Our Impact So Far!
              </h3>
              <p
                className="defaultp"
                data-aos="fade-up"
                data-aos-duration="700"
              >
                Here’s a glance at some of our work
              </p>
            </div>
          </div>
          <Slider {...Testi_Slider}>
            {/* <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='s3_testi one'>
                  <div>
                    <LightgalleryItem src="https://www.youtube.com/watch?v=k2wx7PBaDcU&list=PLFr89EPS-7DWd0F8Wtlp4VgEGyaJF3RJw">
                        <img className='play-btn-st' src="images/play.png" alt='' />
                    </LightgalleryItem>
                    <img className='img-fluid' src='images/testi_guy1.png' /> 
                    </div>
                  <div>
                    <h5>Will Barrymore</h5>
                    <p><b>Creative Director</b></p>
                    <p>Global Company</p>
                  </div>
                </div>
              </div> */}
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="s3_testi two">
                <div>
                  <LightgalleryItem src="https://www.youtube.com/watch?v=N1fQG2_Ce9g&list=PLFr89EPS-7DWd0F8Wtlp4VgEGyaJF3RJw&t=1s">
                    <img className="play-btn-st" src="images/play.png" alt="" />
                  </LightgalleryItem>
                  <img src="images/testi_guy2.png" alt="" />
                </div>
                <div>
                  <h5 className="text-light">Vincent Antonio</h5>
                  <p className="text-light">
                    <b>Church Ministry</b>
                  </p>
                  {/* <p className="text-light">Global Company</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="s3_testi one">
                <div>
                  <LightgalleryItem src="https://www.youtube.com/watch?v=hUpHOil3Qb4&list=PLFr89EPS-7DWd0F8Wtlp4VgEGyaJF3RJw">
                    <img className="play-btn-st" src="images/play.png" alt="" />
                  </LightgalleryItem>
                  <img src="images/testi_guy3.png" alt="" />
                </div>
                <div>
                  <h5 className="">Dr. Barry Taylor </h5>
                  <p className="">
                    <b>Cardiologist</b>
                  </p>
                  {/* <p className="">Global Company</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="s3_testi two">
                <div>
                  <LightgalleryItem src="https://www.youtube.com/watch?v=WG4c6_l-u6A&list=PLFr89EPS-7DWd0F8Wtlp4VgEGyaJF3RJw">
                    <img className="play-btn-st" src="images/play.png" alt="" />
                  </LightgalleryItem>
                  <img className="img-fluid" src="images/testi_guy4.png" />{" "}
                </div>
                <div>
                  <h5 className="text-light">Jacky Ray</h5>
                  <p className="text-light">
                    <b>Creative Director</b>
                  </p>
                  {/* <p className="text-light">Global Company</p> */}
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
}
