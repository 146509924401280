import React, { useEffect } from "react";
// import Aos from 'aos';
import Aos from "aos";
import "aos/dist/aos.css";
import LazyLoad from "react-lazy-load";
// import Slider from 'react-slick';
export default function BadgeSec() {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
    if (window.jQuery(window).width() < 850) {
      window.jQuery(".res_sslider").slick({
        dots: true,
      });
    }
  });
  return (
    <>
      <section className="h_sec5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-6">
              <div
                className="s5_iconbox"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-duration="700"
              >
                <LazyLoad>
                 <img draggable={false}
                    className="img-fluid"
                    alt=""
                    src="images/com-logo1.png"
                  ></img>
                </LazyLoad>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div
                className="s5_iconbox"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-duration="800"
              >
                <LazyLoad>
                 <img draggable={false}
                    className="img-fluid"
                    alt=""
                    src="images/com-logo2.png"
                  ></img>
                </LazyLoad>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div
                className="s5_iconbox"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-duration="900"
              >
                <LazyLoad>
                 <img draggable={false}
                    className="img-fluid"
                    alt=""
                    src="images/com-logo3.png"
                  ></img>
                </LazyLoad>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div
                className="s5_iconbox"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <LazyLoad>
                 <img draggable={false}
                    className="img-fluid"
                    alt=""
                    src="images/com-logo4.png"
                  ></img>
                </LazyLoad>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div
                className="s5_iconbox"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1100"
              >
                <LazyLoad>
                 <img draggable={false}
                    className="img-fluid"
                    alt=""
                    src="images/com-logo5.png"
                  ></img>
                </LazyLoad>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div
                className="s5_iconbox"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1200"
              >
                <LazyLoad>
                 <img draggable={false}
                    className="img-fluid"
                    alt=""
                    src="images/com-logo6.png"
                  ></img>
                </LazyLoad>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div
                className="s5_iconbox"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1300"
              >
                <LazyLoad>
                 <img draggable={false}
                    className="img-fluid"
                    alt=""
                    src="images/com-logo7.png"
                  ></img>
                </LazyLoad>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div
                className="s5_iconbox"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1400"
              >
                <LazyLoad>
                 <img draggable={false}
                    className="img-fluid"
                    alt=""
                    src="images/com-logo8.png"
                  ></img>
                </LazyLoad>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div
                className="s5_iconbox"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1500"
              >
                <LazyLoad>
                 <img draggable={false}
                    className="img-fluid"
                    alt=""
                    src="images/com-logo9.png"
                  ></img>
                </LazyLoad>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div
                className="s5_iconbox"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1600"
              >
                <LazyLoad>
                 <img draggable={false}
                    className="img-fluid"
                    alt=""
                    src="images/com-logo10.png"
                  ></img>
                </LazyLoad>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div
                className="s5_iconbox"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1700"
              >
                <LazyLoad>
                 <img draggable={false}
                    className="img-fluid"
                    alt=""
                    src="images/com-logo11.png"
                  ></img>
                </LazyLoad>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div
                className="s5_iconbox"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1800"
              >
                <LazyLoad>
                 <img draggable={false}
                    className="img-fluid"
                    alt=""
                    src="images/com-logo12.png"
                  ></img>
                </LazyLoad>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
