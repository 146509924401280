import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { LightgalleryItem } from "react-lightgallery";
import Slider from "react-slick";
import { Accordion } from "react-bootstrap";
import CtaSec from "../components/includes/CtaSec";
import Popup from "../components/includes/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

export default function WhiteboardAnimation() {
  document.title = "Artful Whiteboard Animation | Pen, Paper, Perfection";
  // document.getElementsByTagName("META")[2].content =
  //   "Experience the art of visual communication. Our animation team will make unforgettable whiteboard animation. Dive into a world where ideas meet life.";
  var Portfolio_Slider = {
    slidesToShow: 1,
    infinite: true,
    dots: true,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "120px",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "50px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (window.jQuery(window).width() < 767) {
      window.jQuery(".res_slider").slick({
        dots: true,
      });
      window.jQuery(".res_slider2").slick();
    }

    Aos.init();
  });
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.videojeeves.com/Whiteboard-Animation"
        />
        <meta
          name="description"
          content="Experience the art of visual communication. Our animation team will make unforgettable whiteboard animation. Dive into a world where ideas meet life."
        />
      </Helmet>
      <section className="inner_sec1 whiteboard">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h1 className="banner_h1">Whiteboard Animation Videos</h1>
              <p className="defaultp mb-3">
                Whiteboard videos develop strong narratives and provide a fresh
                perspective for the audience.
              </p>
              <p className="defaultp mb-3">
                Video Jeeves brings concepts to life and redefines digital
                education. Our whiteboard animation videos act as a powerful
                medium to build awareness strategies for brands.
              </p>
              <div className="h_btn">
                <Link
                  data-aos="fade-up"
                  data-aos-duration="8600"
                  className="popup-btn white_btn"
                  to="/Contact-us"
                >
                  get a quote
                </Link>
                <Link
                  to="javascript:void(Tawk_API.toggle())"
                  className="pop-btn black_btn chats"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Live Chat
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 banner_play">
              <LightgalleryItem src="https://youtu.be/I4wHi1ndpto?si=frp0KsXE9d-9kP1g">
                <img className="play-btn-st" src="images/play.png" alt="" />
              </LightgalleryItem>
              <img
                alt="Whiteboard Page Section 1 Right"
                className="img-fluid"
                src="images/whiteboard_page/s1right.png"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                alt="Whiteboard Page Section 1"
                className="img-fluid"
                src="images/whiteboard_page/1.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">Dynamic Whiteboard Explainer Videos</h2>
              <p className="defaultp mb-3">
                In an era where the average attention span is shorter than 8
                seconds, leaving a lasting impact has become more crucial than
                ever. The interactive form of visualized content enables you to
                engage with your viewers and drive conversions.
              </p>
              <p className="defaultp mb-3">
                If you are looking for a whiteboard explainer video, we’re the
                ones you should contact.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec3 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">Efficient And Versatile Videos</h2>
              <p className="defaultp mb-3">
                Transform your brand identity with Video Jeeves.
              </p>
              <p className="defaultp mb-3">
                Not only are whiteboard animation videos an efficient way of
                creating and delivering information, but they are also super
                versatile.
              </p>
              <p className="defaultp mb-3">
                Whether you’re a business trying to explain the product you’re
                selling to your audience, or you’re an educational creator
                trying to get a really difficult point across, you can easily do
                that with a whiteboard animation video.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                alt="Whiteboard Page Section 2"
                className="img-fluid"
                src="images/whiteboard_page/2.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="m_sec4 inner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">Standing out in the digital space </h2>
              <p className="defaultp">
                Animated canvases giving life to ideas and making complex
                concepts engaging.
              </p>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/QluN84NeHXc">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    alt="Whiteboard Page Section 4 img1"
                    className="img-fluid"
                    src="images/whiteboard_page/s4img1.png"
                  />
                  <div className="s4_overlay">
                    <h4>Chip Wizard</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/ayb5RVhiDLU?si=rYHr6motuNZuPeVt">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    alt="Whiteboard Page Section 4 img2"
                    className="img-fluid"
                    src="images/whiteboard_page/s4img2.png"
                  />
                  <div className="s4_overlay">
                    <h4>POEM</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/3Xu8ONjbBeA?si=Zpst2diRQwRej1Bs">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    alt="Whiteboard Page Section 4 img3"
                    className="img-fluid"
                    src="images/whiteboard_page/s4img3.png"
                  />
                  <div className="s4_overlay">
                    <h4>Tax Retification Election</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                className="img-fluid"
                src="images/whiteboard_page/3.png"
                alt="Whiteboard Page 3"
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                Giving a Clear Visual Representation
              </h2>
              <p className="defaultp mb-3">
                Whiteboard animation is minimalist, concise, and to the point.
              </p>
              <p className="defaultp mb-3">
                It’s pretty much like a whiteboard cartoon video, watching all
                your ideas jump out of a notebook and start dancing on your
                screen. The stylus we use let us craft a story right in front of
                audiences for better engagement.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="m_sec2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">Stirring innovation into ideas</h2>
              <p className="defaultp mb-1">
                Step into our world, where imagination takes center stage!
              </p>
              <p className="defaultp">
                We're the modern-day wizards coming up with animated wonders.
                Our creative team blends ideas, stirs innovation, and voila! Now
                your vision has transformed into a reality!{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process">
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Concept & Story Creation</span>
                </li>
                <li className="">
                  <img
                    src="images/mainservice-page/arrow.svg"
                    alt="Arrow"
                  ></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Scriptwriting</span>
                </li>
                <li className="">
                  <img
                    src="images/mainservice-page/arrow.svg"
                    alt="Arrow"
                  ></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Storyboarding</span>
                </li>
                <li className="">
                  <img
                    src="images/mainservice-page/arrow.svg"
                    alt="Arrow"
                  ></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Style & Visual design</span>
                </li>
                <li className="">
                  <img
                    src="images/mainservice-page/arrow.svg"
                    alt="Arrow"
                  ></img>
                </li>
                <li className="m_s2_box">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Voiceover & Sound design</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process_gif res_slider2">
                <li className="">
                  <img
                    alt="Process Gif"
                    src="images/mainservice-page/process_1.gif"
                  ></img>
                </li>
                <li className="">
                  <img
                    alt="Process Gif"
                    src="images/mainservice-page/process_2.gif"
                  ></img>
                </li>
                <li className="">
                  <img
                    alt="Process Gif"
                    src="images/mainservice-page/process_3.gif"
                  ></img>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_portfolio">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h2 className="defaulth2">Our Portfolio</h2>
              <p className="defaultp">
                We’re a whiteboard animation video company that offers quality
                services for your business. Take a look at our portfolio!
              </p>
              <Link className="popup-btn white_btn button-86" to="/Contact-us">
                Get started
              </Link>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="portfolio_slider">
                <Slider {...Portfolio_Slider}>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://youtu.be/QluN84NeHXc">
                      <img
                        alt=""
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      />
                    </LightgalleryItem>
                    <img
                      alt=""
                      className="img-fluid"
                      src="images/whiteboard_page/port_img1.png"
                    />
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://youtu.be/3Xu8ONjbBeA?si=Zpst2diRQwRej1Bs">
                      <img
                        alt=""
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      />
                    </LightgalleryItem>
                    <img
                      alt=""
                      className="img-fluid"
                      src="images/whiteboard_page/port_img2.png"
                    />
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://youtu.be/P_VyABU2s7s">
                      <img
                        alt=""
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      />
                    </LightgalleryItem>
                    <img
                      alt=""
                      className="img-fluid"
                      src="images/whiteboard_page/port_img3.png"
                    ></img>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec2 inner_serv">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="h_s2_content">
                <h2
                  className="defaulth2"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  Other Services We Offer
                </h2>
                <p className="defaultp">
                  We make more than just whiteboard animation videos. Check out
                  the other services we offer!
                </p>
              </div>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <LightgalleryItem src="https://youtu.be/AO5Gnv6ZyNg?si=pS88T4h6BhNep8r9">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt=""
                  className="img-fluid"
                  src="images/whiteboard_page/2hire.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/explainer-videos">Explore Explainer Video</Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1100"
              >
                <LightgalleryItem src="https://youtu.be/GP6uI0x47zM?si=cRxHXgugdmHsWljM">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt=""
                  className="img-fluid"
                  src="images/whiteboard_page/investfly.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/motion-graphics-videos">
                      Explore Motion Graphics
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <LightgalleryItem src="https://www.youtube.com/watch?v=f06hVeeH8AE">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt=""
                  className="img-fluid"
                  src="images/whiteboard_page/Bloodymarry.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/3d-videos">Explore 3D Animation</Link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec7">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2
                className="defaulth2"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                FAQs
              </h2>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Why hire Video Jeeves for whiteboard animation services?
                  </Accordion.Header>
                  <Accordion.Body>
                    Our professional approach to executing work is the reason
                    you should hire us. We simplify complex concepts and ensure
                    they grab audiences’ attention immediately.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    How does whiteboard animation work?
                  </Accordion.Header>
                  <Accordion.Body>
                    Whiteboard animation is created through a series of
                    illustrations or drawings that are recorded in sequence. The
                    drawings are often accompanied by a voiceover to explain
                    concepts, tell a story, or convey a message.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Can whiteboard animations be used for marketing purposes?{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    Whiteboard animations are effective for marketing. They can
                    be used in promotional campaigns, social media marketing,
                    website content, and presentations to engage and educate
                    audiences.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    What is the use of whiteboard video animation?
                  </Accordion.Header>
                  <Accordion.Body>
                    It is a kind of explainer video used to describe a product
                    or a subject. Businesses are using such animations to attain
                    audience attention.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <CtaSec></CtaSec>
    </>
  );
}
