import {
  faChevronRight,
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faVimeo,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import footerLogo from "../assets/img/w-logo.png";

export default function Footer() {
  useEffect(() => {
    // window.jQuery(document).ready(function() {
    //     window.jQuery(".ftr-link").click(function () {
    //         window.jQuery(".ftr-link").removeClass("active");
    //         // window.jQuery(".tab").addClass("active"); // instead of this do the below
    //         window.jQuery(this).addClass("active");
    //     });
    //     });
  });


  return (
    <>
      <footer>
        <div className="foot_top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <div className="foot_box">
                  <Link to="/">
                    <LazyLoad>
                      <img
                        alt="Footer Logo"
                        className="img-fluid"
                        src={footerLogo}
                      ></img>
                    </LazyLoad>
                  </Link>
                  <p>The video production company you've been looking for.</p>
                  <div className="newsletterEmail">
                  <span className="">Be The First One To Know!</span>
                    <form className="d-flex align-items-center justify-content-lg-start justify-content-center" action="https://www.videojeeves.com/mail/" method="POST" >
                      <input placeholder="Enter your email" className="email" type="email" required name="em"/>
                      <input className="submitBtn text-light" type="submit" value="Subscribe" />
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-8 col-sm-12 col-xs-12">
                <div className="foot_box">
                  <h3>Contact</h3>
                  <ul className="ul1">
                    <li>
                      <a className="email" href="mailto:info@videojeeves.com">
                        <FontAwesomeIcon icon={faEnvelope} />{" "}
                        info@videojeeves.com
                      </a>
                    </li>
                    <li>
                      <a className="" href="tel:(650) 392-3384">
                        <FontAwesomeIcon icon={faPhone} /> (650) 392-3384
                      </a>
                    </li>
                    <li>
                      <a className="" href="javascript:;">
                        <FontAwesomeIcon icon={faLocationDot} /> 45333 Fremont
                        Blvd #5, Fremont, CA 94538, United States
                      </a>
                    </li>
                    <li className="social">
                      <a
                        className=""
                        href="https://www.facebook.com/thevideojeeves/"
                      >
                        <FontAwesomeIcon fontSize={20} icon={faFacebook} />
                      </a>
                      <a
                        className=""
                        href="https://www.instagram.com/thevideojeeves/"
                      >
                        <FontAwesomeIcon fontSize={20} icon={faInstagram} />
                      </a>
                      <a
                        className=""
                        href="https://linkedin.com/company/video-jeevesofficial/"
                      >
                        <FontAwesomeIcon fontSize={20} icon={faLinkedin} />
                      </a>
                      <a className="" href="https://twitter.com/TheVideoJeeves">
                        <FontAwesomeIcon fontSize={20} icon={faTwitter} />
                      </a>
                      <a
                        className=""
                        href="https://www.youtube.com/channel/UCOQcPocgqb371LM9MmSSuaQ"
                      >
                        <FontAwesomeIcon fontSize={20} icon={faYoutube} />
                      </a>
                      <a className="" href="https://vimeo.com/videojeevesinc">
                        <FontAwesomeIcon fontSize={20} icon={faVimeo} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-4 col-sm-12 col-xs-12">
                <div className="foot_box">
                  {/* <h3>Solutions</h3>
                      <ul>
                        <li><a href="">For Startups</a></li>
                        <li><a href="">For SaaS & Tech</a></li>
                        <li><a href="">For Enterprise</a></li>
                        <li><a href="">For Ecommerce</a></li>
                        <li><a href="">For Agencies</a></li>
                      </ul> */}
                  <h3>Services</h3>
                  <ul>
                    <li className="nav-item text-light">
                      <ul className="inner">
                        {/* <li><Link className="nav-link" to="2d-videos"><i className="fa-solid fa-chevron-right"></i> 2D Explainer Video</Link></li> */}
                        <li>
                          <Link className="nav-link" to="explainer-videos">
                            <FontAwesomeIcon icon={faChevronRight} /> Explainer
                            Videos
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="educational-videos">
                            <FontAwesomeIcon icon={faChevronRight} />{" "}
                            Educational Videos
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="training-videos">
                            <FontAwesomeIcon icon={faChevronRight} /> Training
                            Videos
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="brand-videos">
                            <FontAwesomeIcon icon={faChevronRight} /> Brand
                            Videos
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="social-media-videos">
                            <FontAwesomeIcon icon={faChevronRight} /> Social
                            Media Videos
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="3d-animation-videos">
                            <FontAwesomeIcon icon={faChevronRight} /> 3D
                            Animation Videos
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="motion-graphics-videos">
                            <FontAwesomeIcon icon={faChevronRight} /> Motion
                            Graphics Videos
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="whiteboard-animation">
                            <FontAwesomeIcon icon={faChevronRight} /> Whiteboard
                            Animation
                          </Link>
                        </li>
                        {/* <li><Link className="nav-link" to="cartoon-animation"><i className="fa-solid fa-chevron-right"></i> Cartoon Animation</Link></li> */}
                        {/* <li><Link className="nav-link" to="startup-videos"><i className="fa-solid fa-chevron-right"></i> Startup videos</Link></li> */}
                        {/* <li>
                          <Link className="nav-link" to="live-action-videos">
                            <FontAwesomeIcon icon={faChevronRight} /> live
                            action videos
                    </Link> 
                        </li>
                        */}
                      </ul>
                    </li>
                    {/* <li><a href="">Sitemap</a></li> */}
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                  <div className="foot_box">
                      <h3>Company</h3>
                      <ul>
                        <li><Link className="" to="/about">About Us</Link></li>
                        <li><Link className="" to="/contact">Contact</Link></li>
                        <li><Link to="/term" className=''>Terms & Conditions</Link></li>
                        <li><Link to="/privacy" className=''>Privacy Policy</Link></li>
                        <li className='blog_link'><a href='https://www.videojeeves.com/blog/'>Blog</a></li>
                      </ul>
                      <h3>Resources</h3>
                      <ul>
                        <li><a href="">Learning Center</a></li>
                        <li><a href="">Growth newsletter</a></li>
                        <li><a href="">Strategy session</a></li>
                      </ul>
                  </div>
                </div> */}
            </div>
          </div>
        </div>
        <div className="foot_bot">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-start">
                <p className="m-0">All rights reserved © 2023 Videojeeves</p>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center">
                <div className=" justify-content-center">
                  <a
                    href="https://www.dmca.com/Protection/Status.aspx?ID=d010d8e3-48aa-4023-bb97-887a27529f40"
                    target="blank"
                    className="dmc d-none"
                  >
                    <LazyLoad>
                      <img
                        alt="DMC Badge"
                        className="img-fluid"
                        src="images/dmc.png"
                      ></img>
                    </LazyLoad>
                  </a>
                  <a
                    href="//www.dmca.com/Protection/Status.aspx?ID=d010d8e3-48aa-4023-bb97-887a27529f40"
                    title="DMCA.com Protection Status"
                    className="dmca-badge"
                  >
                    <img
                      src="https://images.dmca.com/Badges/dmca_protected_sml_120l.png?ID=0c0535f8-aa02-4aa8-b3ef-c5b6ad472757"
                      alt="DMCA.com Protection Status"
                    />
                  </a>
                  <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <ul className="list-unstyled d-flex gap-2 justify-content-end terms_bot m-0">
                  <li>
                    <Link to="/terms-and-conditions" className="">
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" className="">
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
              {/* <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 terms_link">
                 <div className='useful'>
                     <Link to="/terms" className=''>Terms & Conditions</Link>
                     <Link to="/privacy" className=''>Privacy Policy</Link>
                 </div>
                </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
