import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Thankyou = memo(() => {
  return (
    <>
    <Helmet>
    <link
      rel="canonical"
      href="https://www.videojeeves.com/thankyou"
    />
  </Helmet>
      <section className="c_sec1 thank_s1">
        <div className="videoSec">
          <video src="images/thankyoupage.mp4" autoPlay muted loop></video>
        </div>
        <div className="container">
          <div className="row align-items-center position-relative z-10">
            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
              <h2 className="defaulth2 text-light">Thankyou!</h2>
              <p className="defaultp text-light w-50 mx-auto">
                We have received your online inquiry and forwarded your
                information to one of our Account Directors who will be in touch
                with you shortly.
                <p className="defaultp text-light mt-3">
                  We look forward to working with you.
                </p>
              </p>
              <Link
                to="/"
                className="bcktohomeBtnThankYou d-table mx-auto mt-4"
              >
                Back To Home Page
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default Thankyou;
