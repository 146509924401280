import React, { useEffect } from "react";
import BadgeSec from "../components/includes/BadgeSec";
import { findByTestId } from "@testing-library/react";
import TestiSec from "../components/includes/TestiSec";
import { Accordion } from "react-bootstrap";
import CtaSec from "../components/includes/CtaSec";
import Slider from "react-slick";
import { LightgalleryItem } from "react-lightgallery";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function Work() {
  document.title = "Mastering the Art of Professional Excellence at Work";
  // document.getElementsByTagName("META")[2].content =
  //   "Embark on a visual journey with our award-winning live-action, 2D, and Motion Graphics animation. Elevate your creativity and let these captivating works of art inspire";
  var Abt_Testi_Slider = {
    slidesToShow: 1,
    infinite: true,
    slidesToScroll: 1,
  };
  var Testi_Slider = {
    slidesToShow: 2,
    infinite: true,
    dots: true,
    arrows: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (window.jQuery(window).width() < 767) {
      window.jQuery(".res_slider").slick({
        dots: false,
        infinite: true,
        arrows: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
      });
    }
    window.jQuery("#search_btn").click(function () {
      // e.preventDefault();
      var select_value = window.jQuery("#type_select").val();
      console.log(select_value);
      if (select_value == "explainer-videos") {
        window.jQuery(".work_box").css("display", "none");
        window.jQuery(".explainer-video").css("display", "block");
      } else if (select_value == "3d-animation") {
        window.jQuery(".work_box").css("display", "none");
        window.jQuery(".three_d").css("display", "block");
      } else if (select_value == "motion-graphics") {
        window.jQuery(".work_box").css("display", "none");
        window.jQuery(".motion-graphics").css("display", "block");
      } else if (select_value == "brand-video") {
        window.jQuery(".work_box").css("display", "none");
        window.jQuery(".brand-video").css("display", "block");
      } else if (select_value == "educational-video") {
        window.jQuery(".work_box").css("display", "none");
        window.jQuery(".educational-video").css("display", "block");
      } else if (select_value == "social-media") {
        window.jQuery(".work_box").css("display", "none");
        window.jQuery(".social-media").css("display", "block");
      } else if (select_value == "startup-ads") {
        window.jQuery(".work_box").css("display", "none");
        window.jQuery(".startup-ads").css("display", "block");
      } else if (select_value == "training-videos") {
        window.jQuery(".work_box").css("display", "none");
        window.jQuery(".training-videos").css("display", "block");
      } else if (select_value == "whiteboard") {
        window.jQuery(".work_box").css("display", "none");
        window.jQuery(".whiteboard").css("display", "block");
      } else {
        window.jQuery(".work_box").css("display", "block");
      }
    });
  });
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Embark on a visual journey with our award-winning live-action, 2D, and Motion Graphics animation. Elevate your creativity and let these captivating works of art inspire"
        />
      </Helmet>
      <section className="a_sec1 work">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-10 col-md-12 col-sm-12 offset-lg-1 text-center ">
              <h1 className="banner_h1">Let Our Work Speak for Itself !</h1>
              <p className="defaultp">
                Discover our award-winning live-action, 2D, and Motion Graphics
                animation. Let them inspire your next video project!{" "}
              </p>
              {/* <div className='h_btn a_btn justify-content-center'>
                        <button className='pop-btn about_btn'> <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                        <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9957 9.55351 24.6247 6.2494 22.1876 3.81236C19.7506 1.37532 16.4465 0.00430006 13 0ZM17.555 13.8325L11.555 17.8325C11.3904 17.9416 11.1974 17.9998 11 18C10.7348 18 10.4804 17.8946 10.2929 17.7071C10.1054 17.5196 10 17.2652 10 17V9C9.99989 8.81891 10.0489 8.6412 10.1419 8.48581C10.2349 8.33043 10.3684 8.20321 10.528 8.11774C10.6877 8.03227 10.8675 7.99176 11.0484 8.00052C11.2293 8.00928 11.4044 8.067 11.555 8.1675L17.555 12.1675C17.6922 12.2588 17.8047 12.3825 17.8825 12.5278C17.9603 12.673 18.001 12.8352 18.001 13C18.001 13.1648 17.9603 13.327 17.8825 13.4722C17.8047 13.6175 17.6922 13.7412 17.555 13.8325Z" fill="#EFC080"/>
                        </svg>See how we work</button>
                    </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="work_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="form-group d-flex gap-2">
                <div className="select_div">
                  <select className="form-control" name="" id="type_select">
                    <option value="" disabled selected>
                      Video Types
                    </option>
                    <option value="all">All Industries</option>
                    <option value="explainer-videos">Explainer Videos</option>
                    <option value="3d-animation">3D Animation</option>
                    <option value="motion-graphics">Motion Graphics</option>
                    <option value="brand-video">Brand Video</option>
                    <option value="educational-video">Educational Video</option>
                    <option value="social-media">Social Media Ads</option>
                    <option value="startup-ads">Startup Ads</option>
                    <option value="training-videos">Training Videos</option>
                    <option value="whiteboard">Whiteboard Animation</option>
                  </select>
                </div>
                <button id="search_btn" className="">
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 work_box explainer-video social-media">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/AO5Gnv6ZyNg?si=pS88T4h6BhNep8r9">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/2hire.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>2Hire</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box whiteboard">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/QluN84NeHXc">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/chip-wizard.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Chip Wizard</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box brand-video motion-graphics">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=semVzZE8AHw">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/compliancetech.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Compliance Tech</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box three_d brand-video social-media startup-ads">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=EAAkVBT229k">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/coolestcustomprint.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Coolest Custom Printing</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box training-videos">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/H8jMo15hpCU?si=fnlbFdzZjtDZ_YrR">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/detego-global.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Detego global- Motion Graphics</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box three_d educational-video training-videos">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=HZ4BG6xZyHU">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/forgiveandforget.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Forgive and Forget</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box brand-video">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=6PbO3QO-RHA">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/logoanimation.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Logo Animation</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box motion-graphics startup-ads">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/TUC44cAayOY?si=cUHeogkCB0AnYrzs">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/municipalclerks.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Municipal Clerks</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box educational-video">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/k_FtN7w-neE">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/ncsu.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>NCSU - 2D Explainer Video</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box three_d">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/m-uzJinTqgo?si=7_LFMDqPk8tpFk05">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/oiristorado.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Osiris Torado</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box three_d">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/Mf2uGPq6N8g?si=UU6ElAV8Fw0Eabfc">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/olivervr.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Oliver VR</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box training-videos">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=XzLNcXfKccw">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/revuud.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Revuud</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box explainer-video">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/zg3NzXulWL8?si=JVbcs5YxxMCRsrMJ">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/rsvp-booth.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>RSVP Photobooth</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box educational-video explainer-video social-media">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=CYm5nW-TB30&t=1s">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/smcpanduit.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>SMC Panduit</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box brand-video explainer-video startup-ads">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/_yXbRAcaqPo?si=hp4QDkUiJzCKhBnk">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/snowbird.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Snowbird Investor</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box explainer-video startup-ads">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=lqCRl2Ki-WE">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/yashsolutions.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Yash Solutions</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box training-videos whiteboard">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/3Xu8ONjbBeA?si=Zpst2diRQwRej1Bs">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/tax-ratification.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Tax Retification Election</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box brand-video explainer-video training-videos">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=TRfsOG-7t_0">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/venturecapital.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Venture Capital</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box whiteboard">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/P_VyABU2s7s">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/vitalsustainers.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Vital Sustainers</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box motion-graphics social-media">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/qgSPyEdZMhU">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/voipfone.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Voipfone</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box educational-video motion-graphics social-media">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=59v2ChrT81w">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/wilburry.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Wilbury</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 work_box motion-graphics">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/mqqQJ-opjGI">
                  {/* <img className='play-btn-st' src="images/play.png" alt='' /> */}
                  <img
                    className="img-fluid"
                    src="images/portfolio/withu.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>WithU</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BadgeSec></BadgeSec>
      <CtaSec></CtaSec>
    </>
  );
}
