import React from "react";
import { useEffect } from "react";

export default function CounterSec() {
  useEffect(() => {
    window.jQuery.fn.jQuerySimpleCounter = function (options) {
      var settings = window.jQuery.extend(
        {
          start: 0,
          end: 100,
          easing: "swing",
          duration: 400,
          complete: "",
        },
        options
      );

      var thisElement = window.jQuery(this);

      window.jQuery({ count: settings.start }).animate(
        { count: settings.end },
        {
          duration: settings.duration,
          easing: settings.easing,
          step: function () {
            var mathCount = Math.ceil(this.count);
            thisElement.text(mathCount);
          },
          complete: settings.complete,
        }
      );
    };

    window
      .jQuery("#number1")
      .jQuerySimpleCounter({ end: 3000, duration: 6000 });
    window
      .jQuery("#number2")
      .jQuerySimpleCounter({ end: 1500, duration: 6000 });
    window
      .jQuery("#number3")
      .jQuerySimpleCounter({ end: 2500, duration: 6000 });
    window.jQuery("#number4").jQuerySimpleCounter({ end: 10, duration: 6000 });
  });
  return (
    <>
      <section className="h_sec3 m_counter">
        <div className="container">
          <div className="row res_slider">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="s3_achievement">
                <h3>
                  <span id="number1" className="">
                    {" "}
                  </span>
                  +
                </h3>
                <p>Videos made</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="s3_achievement">
                <h3>
                  <span id="number2" className=""></span>+
                </h3>
                <p>Collaborations done</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="s3_achievement">
                <h3>
                  <span id="number3" className=""></span>+
                </h3>
                <p>Businesses helped</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="s3_achievement">
                <h3>
                  <span id="number4" className=""></span>+
                </h3>
                <p>Years in business</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
