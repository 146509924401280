import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { LightgalleryItem } from "react-lightgallery";
import Slider from "react-slick";
import CtaSec from "../components/includes/CtaSec";
import { Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function StartupVideos() {
  document.title =
    "Making Startup Videos For Businesses I Bringing Visions To Life";
  document.getElementsByTagName("META")[2].content =
    "Extending business functions. We are a full-service startup video production company providing the best according to clients needs. Click to know about our versatility.";
  var Portfolio_Slider = {
    slidesToShow: 1,
    infinite: true,
    dots: true,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "120px",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "50px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (window.jQuery(window).width() < 767) {
      window.jQuery(".res_slider").slick({
        dots: true,
      });
      window.jQuery(".res_slider2").slick();
    }

    Aos.init();
  });
  return (
    <>
      <section className="inner_sec1 videos">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h1 className="banner_h1">
                A Startup Video Production Company Using Playful Ideas
              </h1>
              <p className="defaultp">
                Not your average video content production team!
                <br />
                We get crazy when it comes to giving ideas. If you want to grow
                fast then, our videos will get you there. We are passionate
                about providing momentum to get you where you need to go. <br />
                These short informative videos created by a start -up video
                production company share businesses or products in the early
                stages of growth.
              </p>
              <div className="h_btn">
                <Link
                  data-aos="fade-up"
                  data-aos-duration="8600"
                  className="pop-btn white_btn"
                  to={"/contact"}
                >
                  get a quote
                </Link>
                <a
                  href="javascript:void(Tawk_API.toggle())"
                  className="pop-btn black_btn chats"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Live Chat
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 banner_play">
              <LightgalleryItem src="https://youtu.be/rtlIYKgNd4w">
                <img className="play-btn-st" src="images/play.png" alt="" />
              </LightgalleryItem>
              <img
                className="img-fluid"
                src="images/startupvideos_page/s1right.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                className="img-fluid"
                src="images/startupvideos_page/1.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">Videos To Binge On </h2>
              <p className="defaultp">
                Not over the top but worth binge-watching. <br />
                Video content production is becoming the standard of online
                entertainment. <br />A dash of our proficiency with a sprinkle
                of brand knowledge. Pairing our expertise with partners'
                knowledge to make the tech wizards envy. We use the latest tools
                and techniques to create unique startup videos. <br />
                Create an instant connection to impact audiences with our video
                production startup. Whether it is about life hacks or a short
                course for the apparel business, our video production startup
                will let you get new knowledge without going deep.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec3 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                Portray Your Business Ideas With Our Startup Video Production
              </h2>
              <p className="defaultp">
                Delivering pitch-perfect videos.
                <br />A start video production company telling how to solve
                promotional tasks. Our ideas will let investors throw money at
                the screen. It will surely become a startup video revolution.
                <br />
                Innovative and unexpected video concepts for startups. <br />
                Our video production house has passionate people who can simply
                tell complex things through their workpieces. We believe video
                is the best way to close the distance between you and your
                customers. Even if you are new to it all we will demonstrate
                everything we know.
                <br />
                Covering a broad scope of marketing requirements with our videos
                tailored to your goals. We not only visualize high-quality ideas
                at speed but also execute them to a superb standard.{" "}
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                className="img-fluid"
                src="images/startupvideos_page/2.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="m_sec4 inner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                A Startup Video Company Igniting Success
              </h2>
              <p className="defaultp">
                Lights, camera, launch <br />
                Make us your start up’s visual partner. A video production for
                startups turns ideas into what sounds ‘’ epic’’.{" "}
              </p>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/_yXbRAcaqPo">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    className="img-fluid"
                    src="images/startupvideos_page/s4img1.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Snowbird Investor</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/H8jMo15hpCU?si=fnlbFdzZjtDZ_YrR">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    className="img-fluid"
                    src="images/startupvideos_page/s4img2.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Detego global- Motion Graphics</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=TUC44cAayOY">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    className="img-fluid"
                    src="images/startupvideos_page/s4img3.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Municipal Clerks</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=EAAkVBT229k">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    className="img-fluid"
                    src="images/startupvideos_page/s4img4.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Coolest Custom Printing</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/qgSPyEdZMhU">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    className="img-fluid"
                    src="images/startupvideos_page/s4img5.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Voipfone</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=lqCRl2Ki-WE">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    className="img-fluid"
                    src="images/startupvideos_page/s4img6.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Yash Solutions</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                className="img-fluid"
                src="images/startupvideos_page/3.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">Launching Startups To Stardom </h2>
              <p className="defaultp">
                Your startup’s fame is just a video away. <br />
                Our startup video production turns unknown into unbelievably
                famous through videos. We know how to put your brand in the
                limelight. VideoJeeves team will surely exceed your
                expectations. They are excellent listeners who take the time to
                understand businesses through videos. <br />
                Willingness to move beyond an ordinary approach. We are here to
                let you get insights on the process and for your next project.{" "}
                <br />
                Composed, captured, conquered, that is how video production
                startups work. This is what we do and we will be continuing to
                present. <br />
                We are like the fairy godmother of startups but instead of a
                wand, we have got a camera. Our startups video will establish
                you as a must-have brand and prove the power of videos as
                exponential.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='inner_sec3 zig_zag'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <h2 className='defaulth2'>Exploring Real-World</h2>
                    <p className='defaultp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit tortor sit amet lectus luctus auctor. Quisque hendrerit pellentesque urna, eu eleifend dolor ornare ac. Nullam ornare tincidunt erat, ut laoreet purus condimentum ut. Fusce feugiat ipsum nisi, sit amet commodo odio imperdiet eu. Mauris luctus consequat arcu quis vestibulum. Aenean laoreet, dui eu rhoncus placerat, dolor turpis euismod lorem, quis eleifend tortor eros id diam. Morbi a tincidunt ex. Cras augue dolor, molestie ut dapibus id, interdum vel felis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit tortor sit amet lectus luctus auctor. Quisque hendrerit pellentesque urna, eu eleifend dolor ornare ac. Nullam ornare tincidunt erat, ut laoreet purus condimentum ut. Fusce feugiat ipsum nisi, sit amet commodo odio imperdiet eu. Mauris luctus consequat arcu quis vestibulum. Aenean laoreet, dui eu rhoncus placerat, dolor turpis euismod lorem, quis eleifend tortor eros id diam. Morbi a tincidunt ex. Cras augue dolor, molestie ut dapibus id, interdum vel felis.</p>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <img className='img-fluid' src='images/innerservice-page/s3right.png'></img>
                </div>
            </div>
        </div>
    </section> */}
      <section className="m_sec2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">Animation Production Process</h2>
              <p className="defaultp">
                At our animation production company, we're the modern-day
                wizards, conjuring animated wonders. Our creative cauldron
                blends ideas, stirs innovation, and voila – captivating
                animations are born. Step into our world, where imagination
                takes center stage, and your vision transforms into whimsical
                realities!
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process">
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Concept & Story Creation</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Scriptwriting</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Storyboarding</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Style & Visual design</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Voiceover & Sound design</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process_gif res_slider2">
                <li className="">
                  <img src="images/mainservice-page/process_1.gif"></img>
                </li>
                <li className="">
                  <img src="images/mainservice-page/process_2.gif"></img>
                </li>
                <li className="">
                  <img src="images/mainservice-page/process_3.gif"></img>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_portfolio">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h2 className="defaulth2">Our Portfolio</h2>
              <p className="defaultp">
                We are a startup video company that offers quality services for
                your business. Take a look at our portfolio!
              </p>
              <a className="popup-btn white_btn button-86" href="javascript:">
                Get started
              </a>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="portfolio_slider">
                <Slider {...Portfolio_Slider}>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://youtu.be/H8jMo15hpCU?si=fnlbFdzZjtDZ_YrR">
                      <img
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      ></img>
                    </LightgalleryItem>
                    <img
                      className="img-fluid"
                      src="images/startupvideos_page/port_img1.png"
                    ></img>
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://www.youtube.com/watch?v=TUC44cAayOY">
                      <img
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      ></img>
                    </LightgalleryItem>
                    <img
                      className="img-fluid"
                      src="images/startupvideos_page/port_img2.png"
                    ></img>
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://youtu.be/_yXbRAcaqPo?si=hp4QDkUiJzCKhBnk">
                      <img
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      ></img>
                    </LightgalleryItem>
                    <img
                      className="img-fluid"
                      src="images/startupvideos_page/port_img3.png"
                    ></img>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec2 inner_serv">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="h_s2_content">
                <h2
                  className="defaulth2"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  Explore Our Services{" "}
                </h2>
                <p className="defaultp">We have done so much more. </p>
              </div>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <LightgalleryItem src="https://www.youtube.com/watch?v=HZ4BG6xZyHU">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  className="img-fluid"
                  src="images/startupvideos_page/firgiveandforget.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/educational-videos">
                      Explore Educational Videos
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1100"
              >
                <LightgalleryItem src="https://www.youtube.com/watch?v=_yXbRAcaqPo&list=PLFr89EPS-7DU3dEsDS1wE2hjGkHwPjyJt&index=5">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  className="img-fluid"
                  src="images/startupvideos_page/snowbird.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/brand-videos">Explore Brand Videos</Link>{" "}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <LightgalleryItem src="https://www.youtube.com/watch?v=7uPwJwfar8Y">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  className="img-fluid"
                  src="images/startupvideos_page/venturecapital.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/training-videos">Explore Training Videos</Link>{" "}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec7">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2
                className="defaulth2"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                FAQs
              </h2>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    How do I get started with a video project for my business?{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    All you need to do is simply reach out to us through our
                    contact page. You can send us an email or schedule a
                    consultation to discuss your project goals.{" "}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Why do we need a startup video company to produce video
                    content?{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    Video content is a powerful tool for startups to convey
                    their message and showcase products or services. This helps
                    startups to stand out in a crowded market and attract
                    investors.{" "}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    How much does a video production startup cost?
                  </Accordion.Header>
                  <Accordion.Body>
                    It depends on the length and style of videos. We can only
                    give an estimate. It might cost you around $20,000 - $55000.{" "}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    How will be the experience working with VideoJeeves?{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    {" "}
                    We understand it is difficult to tell a story. So, define
                    your project by structuring all the pointers and hope to
                    leave a lasting influence on marketing.{" "}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <CtaSec></CtaSec>
    </>
  );
}
