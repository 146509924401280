import React from 'react'
import { Helmet } from 'react-helmet'

export default function Term() {
  return (
    <>
    <Helmet>
    <link
      rel="canonical"
      href="https://www.videojeeves.com/terms-and-conditions"
    />
  </Helmet>
    <section className='useful_banner'>
        <div className='container'>
            <div className='row'>
                <div className="col-md-12">
                    <h1><strong>Terms and Conditions</strong></h1>
                </div>
            </div>
        </div>
    </section>
    <section className="production-sec">
    <div className="container">
        <div className="production-sec-head-1">
            <p>
                While using the services offered, you are deemed to have read and agreed to the following terms and
                conditions:<br />
                The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer
                Notice, and any or all Agreements: "Client", "Customer", "You" and "Your" refers to you, a person using
                services/ product of Video Jeeves. "The Producer", "Ourselves", "We" and "Us", refers to our
                Producer.<br />
                "Party", "Parties", or "Us", refers to both the Client and ourselves, or either the Client or ourselves.
                Service and/or services refers to products and services provided by Producer (including but not limited
                to design, artwork, development, tests, documentation, illustrations, voiceover, animation, music, sound
                effects, and consultation).<br />
                All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the
                process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a
                fixed duration or any other means, for the express purpose of meeting the Client's needs in respect of
                the provision of the Producer's stated services/products, in accordance with and subject to, prevailing
                Law.<br />
                Any use of the above terminology or other words in the singular, plural, capitalization, and/or he/she
                or they are taken as interchangeable and, therefore, as referring to the same.<br />
            </p>
            <h3>Disclaimer Exclusions and Limitations</h3>
            <p>
                The information on this website is provided on an "as is" basis. To the fullest extent permitted by law,
                this Producer will not sell, share, or rent your personal information to any third party or use your
                email address for unsolicited mail. Any direct emails sent intentionally by this Producer will only be
                in connection with the provision of agreed services and products. Communications that are received from
                this Producer through any error, mistake, and/or accident, including but not limited to the inadvertent
                addition of an email address from different listservs, client lists, and other means of obtaining
                information about a group of users.<br />
                This excludes all representations and warranties relating to this project and its contents or which is
                or may be provided by any affiliates or any other third party, including in relation to any inaccuracies
                or omissions in this media and/or the Producer's literature, and excludes all liability for damages
                arising out of or in connection with your use of this media.<br />
                This includes without limitation, direct loss, loss of business or profits (whether or not the loss of
                such profits was foreseeable, arose in the normal course of things, or you have advised this Producer of
                the possibility of such potential loss), damage caused to your computer, computer software, systems, and
                programs and the data thereon or any other direct or indirect, consequential and incidental
                damages.<br />
                The above exclusions and limitations apply only to the extent permitted by law. None of your statutory
                rights as a client are affected.
            </p>
            <h3>Payment &amp; Milestones</h3>
            <p>
                Payments can be made via Checks, all major Credit/Debit Cards, and Bank Transfers. The payment cycle, as
                discussed, will be within a period of 45 days. All services remain the property of the Producer until
                paid for in full.<br />
                <b>Recurring Payments:</b><br />
                The billing will be made if it is in the form of a one-time payment or an automatically recurring
                payment, which is described in any of the specific product/service terms and conditions regarding the
                service provided. If the billing is defined as an automatically recurring payment, then all the payments
                of the agreed services should be made in advance every month/project cycle.<br />
                <b>Late Payment Policy and Penalties:</b><br />
                Timely payment is essential to ensure the smooth progression of the project and maintain a positive
                working relationship. The client agrees to adhere to the agreed-upon payment terms and schedule as
                outlined in the project agreement<br />
            </p>
            <ul>
                <li> In the event of late payment, the client may be subject to penalties or late fees as specified in
                    the project agreement.</li>
                <br />
                <li> If payment is not received within the designated timeframe, the Producer reserves the right to
                    suspend or delay the project until payment is received in full.</li>
                <br />
                <li> The client acknowledges that any delays in payment may impact the project timeline and potentially
                    incur additional costs or rescheduling fees.</li>
                <br />
                <li> The Producer may also take appropriate legal action to recover any outstanding payments, including
                    but not limited to, engaging the services of debt collection agencies or pursuing legal remedies.
                </li>
                <br />
            </ul>
            <p></p>
            <h3>Milestones</h3>
            <ul>
                <li><span>Upfront milestone:</span><br/>
                    This milestone occurs once all the project requirements have been gathered and the proposal is
                    signed. It serves as the initial stage where the project officially begins.
                </li>
                <br />
                <li><span>Scriptwriting and moodboard milestone:</span><br/>
                    This milestone involves the development of the video script and the creation of a visual moodboard
                    that captures the intended style and tone of the animation.
                </li>
                <br />
                <li><span> Storyboard and voiceover milestone:</span><br/>
                    At this milestone, the storyboard, which outlines the visual sequence of the animation, is presented
                    for client approval. Additionally, the voiceover, if applicable, is recorded and shared for
                    feedback.
                </li>
                <br />
                <li><span>First draft of animation milestone:</span><br/>
                    This milestone marks the completion of the first draft of the animation, incorporating the approved
                    script, storyboard, and voiceover. The client will have the opportunity to review and provide
                    feedback on this initial version.
                </li>
                <br />
                <h3>Termination of Agreements</h3>
                <p>Both the Client and Producer have the right to terminate any Services Agreement for any reason,
                    including the ending of services that are already underway.<br />
                    No refunds shall be offered where a service is deemed to have begun and is, for all intents and
                    purposes, underway. Any monies that have been paid to us, which constitute payment in respect of the
                    provision of unused Services, shall be refunded if work/ research or internal discussions have not
                    been initiated. It is the responsibility of the Client to pay in full the final amount due to the
                    Producer for all work performed up to the moment of termination. If Producer has partially completed
                    a phase, sprint, or other.<br /><br />
                    Notice of cancellation shall be delivered via email, to the email addresses as noted in the
                    Production Agreement. In the event that the termination occurs via telephone Call and/or fax, it
                    will only be accepted once it is confirmed in writing.
                </p>
                <h3>Links to This Website</h3>
                <p>
                    You may not create a link to any page of this website without our prior written consent. If you do
                    create a link to a page of this website, you do so at your own risk, and the exclusions and
                    limitations set out above will apply to your use of this website by linking to it.<br /><br />
                    We do not monitor or review the content of other parties' websites that are linked to or from this
                    website. Opinions expressed or material appearing on such websites are not necessarily shared or
                    endorsed by us, and should not be regarded as the publisher of such opinions or material.<br /><br />
                    Please be aware that we are not responsible for the privacy practices or content of those sites. We
                    encourage our users to be aware when they leave our site &amp; to read the privacy statements of
                    those sites.<br /><br />
                    You should evaluate the security and trustworthiness of any other site connected to this site or
                    accessed through this site yourself before disclosing any personal information to them.<br /><br />
                    This Company will not accept any responsibility for any loss or damage in whatever manner, howsoever
                    caused, resulting from your disclosure to third parties of personal information.
                </p>
                <h3>Copyright Notice</h3>
                <p> Copyright and other relevant intellectual property rights exist on all text, images &amp; portfolios
                    relating to the Producer's services and the full content of the production.<br /><br />
                    This Producer's logo is a registered trademark of this Producer in the United States and other
                    countries. The brand names and specific services of this Producer featured on this website are
                    trademarked.
                </p>
                <h3>Communication</h3>
                <p>
                    Each of our clients has an assigned account manager/ client service manager and initial sales
                    representative (at times, both duties are performed by a single person).<br /><br />
                    The Producer makes sure that the name, email, and phone number of the assigned person(s) are shared
                    with the client. The Producer's general contact information is mentioned on this media for new
                    prospective clients and for existing clients who are unable to reach their assigned
                    person(s).<br /><br />
                    The general contact information can be used by anyone to contact the Producer.
                </p>
                <h3>Force Majeure</h3>
                <p> Neither party shall be liable to the other for any failure to perform any obligation under any
                    Agreement which is due to an event beyond the control of such party, including but not limited to
                    any Act of God, terrorism, war, Political insurgence, insurrection, riot, pandemic, epidemic, civil
                    unrest, an act of civil or military authority, uprising, earthquake, flood or any other natural or
                    manmade eventuality outside of our control, which causes the termination of an agreement or contract
                    entered into, nor which could have been reasonably foreseen.<br /><br />
                    Any Party affected by such event shall forthwith inform the other Party of the same and shall use
                    all reasonable endeavors to comply with the terms and conditions of any Agreement contained herein.
                </p>
                <h3>Waiver</h3>
                <p> Failure of either Party to insist upon strict performance of any provision of this or any Agreement
                    or the failure of either Party to exercise any right or remedy to which it, he, or they are entitled
                    hereunder shall not constitute a waiver thereof and shall not cause a diminution of the obligations
                    under this or any Agreement.<br /><br />
                    No waiver of any of the provisions of this or any Agreement shall be effective unless it is
                    expressly stated to be such and signed by both Parties.
                </p>
                <h3>Refund Policy</h3>
                <p>
                    The Producer strives to provide services to the best of its abilities. However, in case of any
                    unforeseen event, the client holds the right to request a refund of the money paid to the
                    Producer.<br /><br />
                    The Producer is liable to process this request within a maximum of 60 days. The return request will
                    be approved if it complies with the Producer's refund policy.<br /><br />
                    The money will be transferred back to the client's account either through the credit card account or
                    to the bank account that was recommended by the client.<br /><br />
                    A full or partial refund will be made in the following circumstances:
                </p>
                <ul>
                    <li>Refund is requested within a 7-day time of initial preview delivery</li>
                    <br />
                    <li>If the first preview is completely non-compliant with the creative brief of the client</li>
                    <br />
                    <li>If the team is not able to deliver the first milestone within the specified timeline</li>
                    <br />
                    <li>A revision round has yet to be initiated yet</li>
                    <br />
                </ul>
                <br />
                <p>A full or partial refund will NOT be made for any of the following circumstances:</p>
                <ul>
                    <li>If the client does not respond within 7 days of the project cycle or milestone’s deliverance.
                    </li>
                    <br />
                    <li>On an already-completed and delivered project cycle or milestone .</li>
                    <br />
                    <li>After the first milestone is mutually agreed-upon.</li>
                    <br />
                    <li>If the revision or alteration is requested by the client regarding any of the services provided
                        by the Producer during the creation of the media.</li>
                    <br />
                    <li>If the client discontinues the operations, changes its name or its activity. Any changes to the
                        client’s operational, business, or corporate standing shall have no bearing on any outstanding
                        financial obligations due to the Producer.</li>
                    <br />
                    <li>If the refund is requested for any reasons that were made without involving the Producer in any
                        way.</li>
                    <br />
                    <li>Change of mind: Definition of change of mind, a decision to reverse an earlier decision.
                        Synonyms: flip-flop, reversal, turnabout. Types: afterthought, reconsideration, rethink, second
                        thought, thought again about a choice previously made.</li>
                    <br />
                    <li>Partial Refunds: Partial refunds are subject to the decision based on the above conditions.</li>
                    <br />
                </ul>
                <p></p>
                <p><b>Note: Bank and refund processing fees are applicable on all refunds.</b></p>
                <h3>Limited Revisions</h3>
                <p>
                    Producer limited revisions. This means that the client can enjoy a reasonable number of edits,
                    revisions, comments, and changes in a certain revision round.</p>
                <b>Revision Rounds</b><br /><br />
                <p>
                    Similar to how limited revisions are allowed for each stage of the project, revision rounds are also
                    limited. Clients are advised and encouraged to include clear and cohesive feedback in each revision
                    round so that the best possible work can be delivered within the least amount of time.
                    <br />
                    The number of revision rounds differs according to the package sold and the scope of the work, which
                    is discussed before the start of the project.
                    <br />
                    Revision rounds are restricted to a certain number per project, and a client can avail a fixed
                    number of revision rounds at each stage of the project.
                </p>
                <h3>Changes to Project Scope</h3>
                <p>
                    The prices mentioned in the proposal only contain the work that is discussed by the customer service
                    representative or project consultant.
                    If a change to the scope of the project once the agreement is sought, the Client is required to
                    provide written change order mentioning the details of the changes required.<br /><br />
                    Once the written change order is submitted to the Producer, the Project Manager will respond
                    accordingly with a statement of revised terms that will consist of the details regarding any
                    additional fee, changes in delivery dates, and if any change is needed to the terms and conditions
                    agreement.<br /><br />
                    The Project Manager is only authorized to work beyond the original scope and if the client agrees to
                    the modifications made to the new scope of the project.
                </p>
                <h3> Change Request </h3>
                <p>
                    Any request in the ongoing project that was not originally discussed and documented will be
                    considered as a change request. All the change requests will be charged additionally based on their
                    complexity. The client acknowledges and agrees that this change request will also affect the overall
                    deadline of the project.
                </p>
                <h3>Deadlines/ Timelines</h3>
                <p>Since the Producer offers limited revisions (as explained in point no.10), the deadlines/ timelines
                    provided by the Producer are tentative and are not exact. The Producer cannot be held responsible
                    for not meeting deadlines and timelines if revisions, feedback or approval are provided to the
                    Producer after 12-14 working hours for any of the services. Any Delay in revision, feedback or
                    approval will also affect the deadline/ timeline.</p>
                <h3>Intellectual Property</h3>
                <p>Every service and all of its elements provided by the Producer will be under 100% ownership rights of
                    the client. Upon the written consent of the Client, the Producer, and its partners may include the
                    project in the work sections of the Producer’s portfolio of the Company.</p>
                <h3>Limited Liability</h3>
                <p>The Producer has limited liability and is not responsible for any profit or loss you make from the
                    services provided by the Producer. The Producer is only liable to provide the services Producer has
                    been hired for.</p>
                <h3>Notification of Changes</h3>
                <p>The Company reserves the right to change these conditions from time to time as it sees fit, and your
                    continued use of the site will signify your acceptance of any adjustment to these terms.</p>
                <h3>Consent</h3>
                <p>By hiring Producer to provide any digital media services, Company accepts the privacy policy of the
                    Producer. In case of any questions, concerns, or conflicts, please feel free to email the Producer
                    at <a href="mailto:info@videojeeves.com">info@videojeeves.com</a><br />
                    These terms and conditions form a supplemental part of the contemplated and/or previously-executed
                    Agreement between the Client and Producer. Your accessing of this website and/or undertaking of a
                    booking or Agreement indicates your understanding, agreement to and acceptance of the Disclaimer
                    Notice and the full Terms and Conditions contained herein. Your statutory Consumer Rights are
                    unaffected.<br />
                </p>
                <h3>Ownership Rights</h3>
                <br />
                <p>The final video design/file belongs to Producer until all the invoices are paid fully by the client.
                    If termination of the agreement takes place before the final delivery and payment, the design will
                    remain with Producer, and the Producer holds the right to exhibit, re-use, and/or sell the video or
                    its resources (after omitting the business name).<br /><br />
                    Upon satisfaction of any and all financial obligations, the Client has the right to use the video as
                    per their need and purpose.<br />
                </p>
                <h3>Promotion Rights</h3>
                <p>Producer reserves the right to showcase the final product in the portfolio section of the Producer
                    website and share it publicly to different social/web platforms for portfolio promotional purposes
                    without any hindrance or limitation, until and unless the client provides written objection.</p>
                <h3>Delays</h3>
                <p>
                    To keep the work in progress the client must cooperate with the Producer team by providing the
                    necessary information, material, and approvals on time. Any delays will result in the extension of
                    delivery dates for the work agreed on in the proposal.<br /><br />
                    Any of the delays made by either party but not restricted to natural disasters, acts of government,
                    acts of God and fire, pandemics, epidemics, floods, and power failures will not be considered
                    because of day-for-day extension of any performance due.<br /><br />
                    The project cost overruns within a standard project timeline that is of 3 months maximum, if caused
                    due to miscommunication and/or inactivation of the project from the client's side will be charged as
                    $500 or 10% of the complete project cost.<br /><br />
                    All the pricing packages of the projects are of 3 months service level agreement, that is the prices
                    are applicable for three months.<br /><br />
                    Unless otherwise mentioned in the contract or invoice, all projects are subject to suspension and
                    termination. After 3 months, new charges apply to reactive projects, and the Producer reserves the
                    right to continue or stop the project.<br /><br />
                    Once complete payment is made, the client will be allowed to get the complete project file and
                    backup of the project.<br /><br />
                    The producer holds no responsibility regarding any loss/damages that are caused by the delays,
                    suspension, termination, or deletion of the projects whatsoever.<br /><br />
                </p>
                <h3>Additional Expenses</h3>
                <p>The project quotation that the Client receives via email specifies the charges for services to be
                    performed by the Producer4 and for 15-20 days, quotations remain valid. After the specified days
                    have passed, Producer reserves the right to change or refuse to offer a quotation.</p>
                <h3>Project Pause/Restart Clause</h3>
                <p>
                    The client has the right to pause their project due to any reason (lack of availability of
                    resources, payment, material, etc). This should be communicated in writing to the corresponding
                    project manager and their subsequent approval.<br />
                    The maximum time duration for which a project can be paused is 9 - 12 months. After which, the
                    project will be suspended automatically by the project management team.<br />
                    Resuming suspended projects will require a restart fee of $250 or 10% of the project cost (whichever
                    is lower).<br />
                    During this time (Paused), the client can get back to the project manager via written notice stating
                    that they are ready to proceed ahead with the project and it will be resumed from the last
                    milestone.<br />
                    You can inquire for more information about this clause from the corresponding member of the Producer
                    team.<br />
                    To keep the work in progress the client must cooperate with the team Producer by providing the
                    necessary information, material, and approvals on time. Any delays may result in your project being
                    moved into a lower priority slab and consequently may result in the extension of delivery dates for
                    the work agreed on, in the proposal.
                </p>
                <h3>Automated Payments and Milestone Completion</h3>
                <p>Automated payments are a fundamental part of the Producer's billing process. Upon the milestone
                    completion date and delivery of the agreed-upon deliverables, an automated invoice will be promptly
                    issued to the client.<br />
                </p>
                <ul>
                    <li> The client acknowledges that the payment for each milestone is due upon receipt of the invoice.
                        The Producer reserves the right to specify the accepted payment methods and terms in the project
                        agreement.</li>
                    <br />
                    <li> It is essential for the client to ensure that sufficient funds are available and that the
                        automated payment is successfully processed within the designated payment period.</li>
                    <br />
                    <li> The client acknowledges and agrees that the automated payment will be triggered upon milestone
                        completion and delivery of the agreed-upon deliverables, regardless of any additional revision
                        requests or changes that may arise thereafter.</li>
                    <br />
                    <li> The client understands and agrees that the project will not progress to the next milestone
                        until the payment for the current milestone has been received and confirmed by the Producer.
                    </li>
                    <br />
                </ul>
                <p></p>
                <h3>Feedback Policy</h3>
                <p>The Client agrees that any feedback, reviews, or testimonials provided regarding the services
                    rendered by the Producer shall accurately represent their personal experience with the project and
                    shall not intentionally harm the reputation or business interests of the Company.
                </p>
                <ul>
                    <li>In the event that the Client has been deemed to be in breach of the terms and conditions
                        outlined in this agreement, including but not limited to failure to fulfill their obligations as
                        stipulated in Point 20, the Client expressly waives their right to provide derogatory or harmful
                        reviews, comments, or testimonials about the Producer.</li>
                    <br />
                    <li>The Producer reserves the right to take appropriate legal action to seek damages or obtain
                        injunctive relief in the event that the Client violates the provisions of this clause, causing
                        direct harm to the reputation or business interests of the Producer.</li>
                    <br />
                    <li> The Producer shall not be held responsible for any negative reviews or feedback provided by
                        third parties, including but not limited to the Client's associates, employees, or affiliates.
                        The Client shall be solely responsible for managing and ensuring the conduct of such third
                        parties in accordance with the terms and conditions of this agreement.</li>
                    <br />
                    <li> The limitations mentioned herein shall not restrict the Client's rights to provide truthful,
                        non-defamatory, and fact-based information about their project experience with the Producer when
                        directly asked by potential clients, partners, or industry professionals. The Producer
                        acknowledges the importance of transparency and truthful communication.</li>
                </ul>
                <p></p><br /><br />
                <h3>Non-Solicitation</h3>
                <p>During the term of this Agreement and for a period of one (1) year thereafter, neither Party shall,
                    directly or indirectly, on behalf of itself or any other person, solicit for employment or
                    engagement any person who is then, or who was at any time during the term, employed or engaged as an
                    employee, contractor, sales representative, member, director, officer, or otherwise engaged in any
                    professional, managerial, or other position with the other Party, or otherwise influence, or attempt
                    to influence, any employee, contractor, sales representative, member, director, or officer to
                    terminate their employment or relationship with the other Party. Neither Party shall directly or
                    indirectly, on behalf of itself or any other person, solicit or otherwise influence, or attempt to
                    influence, the business or customers, leads, or prospects who were at any time during the term,
                    engaged with the other Party.</p>
                <h3>Sign-Offs and Milestone Approval</h3>
                <p>Written acknowledgment or sign-offs are mandatory for each milestone of the project. The client
                    agrees to provide timely feedback and approvals at each milestone stage as specified in the project
                    timeline.<br /><br />
                </p>
                <ul>
                    <li> Once a milestone is approved and signed off by the client, it is considered final and binding.
                        No further modifications or revisions can be made to that specific milestone.?</li>
                    <br />
                    <li> In the event that a new request or change arises after a milestone has been approved, it will
                        be treated as a separate change request, subject to additional charges and time requirements.?
                    </li>
                    <br />
                    <li> The client acknowledges that any new requests or changes after milestone approval may result in
                        adjustments to the project timeline and incur additional costs. The Producer will promptly
                        provide the client with a quote for the additional work and any associated charges.?</li>
                    <br />
                </ul>
                <p></p>
                <p>
                    The video animation project consists of four milestones:<br /><br />
                    <b>1. First Milestone:</b><br />
                    This milestone occurs once all the project requirements have been gathered and the proposal is
                    signed. It serves as the initial stage where the project officially begins.<br />
                    <b>2. Second Milestone:</b><br />
                    This milestone involves the development of the video script and the creation of a visual moodboard
                    that captures the intended style and tone of the animation.<br />
                    <b>3. Third Milestone:</b><br />
                    At this milestone, the storyboard, which outlines the visual sequence of the animation, is presented
                    for client approval. Additionally, the voiceover, if applicable, is recorded and shared for
                    feedback.<br /><br />
                    <b>4. Fourth Milestone:</b><br />
                    This milestone marks the completion of the first draft of the animation, incorporating the approved
                    script, storyboard, and voiceover. The client will have the opportunity to review and provide
                    feedback on this initial version.
                    <br /><br />
                </p>
            </ul>
        </div>
    </div>
</section>
    </>
  )
}
