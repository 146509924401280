import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { LightgalleryItem } from "react-lightgallery";
import Slider from "react-slick";
import CtaSec from "../components/includes/CtaSec";
import { Accordion } from "react-bootstrap";
import Popup from "../components/includes/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

export default function TrainingVideos() {
  document.title = "Empower Training Videos | Learn, Practice, Succeed";
  // document.getElementsByTagName("META")[2].content =
  //   "Invest in your future success with our training videos. We design videos for a seamless learning that lead you to success by addressing the unique challenges.";
  var Portfolio_Slider = {
    slidesToShow: 1,
    infinite: true,
    dots: true,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "120px",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "50px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (window.jQuery(window).width() < 767) {
      window.jQuery(".res_slider").slick({
        dots: true,
      });
      window.jQuery(".res_slider2").slick();
    }

    Aos.init();
  });
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.videojeeves.com/Training-Videos"
        />
        <meta
        name="description"
        content="Invest in your future success with our training videos. We design videos for a seamless learning that lead you to success by addressing the unique challenges."
      />
      </Helmet>
      <section className="inner_sec1 videos">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h1 className="banner_h1">
                Let’s spice up the learning curve with training videos
              </h1>
              <p className="defaultp">
                We are not just shaping minds; we are crafting the future of
                learning.
                <br />
                Our training video production company makes it easier for
                audiences to understand any topic.
                <br />
                The visual learning assets explain key processes in a simplistic
                way.
              </p>
              <div className="h_btn">
                <Link
                  data-aos="fade-up"
                  data-aos-duration="8600"
                  className="popup-btn white_btn"
                  to="/Contact-us"
                >
                  get a quote
                </Link>
                <a
                  href="javascript:void(Tawk_API.toggle())"
                  className="pop-btn black_btn chats"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Live Chat
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 banner_play">
              <LightgalleryItem src="https://www.youtube.com/watch?v=P2hv3JyxLwY&list=PLFr89EPS-7DX5lkHOLrV9VdGCs7QUG0Hc&index=4">
                <img className="play-btn-st" src="images/play.png" alt="" />
              </LightgalleryItem>
              <img
                className="img-fluid"
                src="images/trainingvideos_page/s1right.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                className="img-fluid"
                src="images/trainingvideos_page/1.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">Visual-oriented stories</h2>
              <p className="defaultp">
                If you're expecting a typical, dull PowerPoint presentation from
                us, think again. As a training video production company, we
                redefine learning.
                <br />
                Crisp quality and captivating videos make it easy for viewers to
                grasp the knowledge without any distractions. <br />
                The videos don’t just share information; they reignite the
                viewer's passion for learning. <br />
                We merge audio and visual elements seamlessly, which transforms
                content.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec3 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 ">
              <h2 className="defaulth2">Taking your learning up a notch</h2>
              <p className="defaultp">
                The training videos are surely a shortcut, serving all your
                needs.. <br />
                Training video production at Video Jeeves enables you to provide
                instructions in a way that resonates with your viewers. <br />
                With a blend of knowledge and animation training video
                production services make the underlying concepts come alive to
                students. <br />
                Tell us your video needs and our team will execute it on the go.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                className="img-fluid"
                src="images/trainingvideos_page/2.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="m_sec4 inner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">An Interesting Side Of Learning</h2>
              <p className="defaultp">
                Training videos, surely a yayy option to teach. You have hit the
                jackpot because we're not just your ordinary training video
                creators.
              </p>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=7uPwJwfar8Y">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  {/* <LightgalleryItem></LightgalleryItem> */}

                  <img
                    className="img-fluid"
                    src="images/trainingvideos_page/s4img1.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Venture Capital</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=HZ4BG6xZyHU&t=13s">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/trainingvideos_page/s4img2.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Forgive and Forget</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=XzLNcXfKccw">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/trainingvideos_page/s4img3.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Revuud</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/H8jMo15hpCU?si=fnlbFdzZjtDZ_YrR">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/trainingvideos_page/s4img4.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Detego global- Motion Graphics</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=3Xu8ONjbBeA&t=31s">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/trainingvideos_page/s4img5.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Tax Ratification</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=59v2ChrT81w">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/trainingvideos_page/s4img6.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Wilbury</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                className="img-fluid"
                src="images/trainingvideos_page/3.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                Training with a punch of playfulness
              </h2>
              <p className="defaultp">
                Learning has never been this easy. Because from dry to droll,
                training videos will make all pie charts fun. <br />
                Our training videos describe everything in detail and keep the
                viewers entertained. These videos will give your a direct and
                unfiltered view, allowing for a great visual experience.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='inner_sec3 zig_zag'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <h2 className='defaulth2'>Exploring Real-World</h2>
                    <p className='defaultp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit tortor sit amet lectus luctus auctor. Quisque hendrerit pellentesque urna, eu eleifend dolor ornare ac. Nullam ornare tincidunt erat, ut laoreet purus condimentum ut. Fusce feugiat ipsum nisi, sit amet commodo odio imperdiet eu. Mauris luctus consequat arcu quis vestibulum. Aenean laoreet, dui eu rhoncus placerat, dolor turpis euismod lorem, quis eleifend tortor eros id diam. Morbi a tincidunt ex. Cras augue dolor, molestie ut dapibus id, interdum vel felis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit tortor sit amet lectus luctus auctor. Quisque hendrerit pellentesque urna, eu eleifend dolor ornare ac. Nullam ornare tincidunt erat, ut laoreet purus condimentum ut. Fusce feugiat ipsum nisi, sit amet commodo odio imperdiet eu. Mauris luctus consequat arcu quis vestibulum. Aenean laoreet, dui eu rhoncus placerat, dolor turpis euismod lorem, quis eleifend tortor eros id diam. Morbi a tincidunt ex. Cras augue dolor, molestie ut dapibus id, interdum vel felis.</p>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <img className='img-fluid' src='images/innerservice-page/s3right.png'></img>
                </div>
            </div>
        </div>
    </section> */}
      <section className="m_sec2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">Stirring innovation into ideas</h2>
              <p className="defaultp">
                Step into our world, where imagination takes center stage!
                <br />
                We're the modern-day wizards coming up with animated wonders.
                Our creative team blends ideas, stirs innovation, and voila! Now
                your vision has transformed into a reality!
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process">
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Concept & Story Creation</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Scriptwriting</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Storyboarding</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Style & Visual design</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Voiceover & Sound design</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process_gif res_slider2">
                <li className="">
                  <img src="images/mainservice-page/process_1.gif"></img>
                </li>
                <li className="">
                  <img src="images/mainservice-page/process_2.gif"></img>
                </li>
                <li className="">
                  <img src="images/mainservice-page/process_3.gif"></img>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_portfolio">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h2 className="defaulth2">Our Portfolio</h2>
              <p className="defaultp">
                We are a training video company that offers quality services for
                your business. Take a look at our portfolio!
              </p>
              <Link className="popup-btn white_btn button-86" to="/Contact-us">
                Get started
              </Link>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="portfolio_slider">
                <Slider {...Portfolio_Slider}>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://www.youtube.com/watch?v=XzLNcXfKccw">
                      <img
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      ></img>
                    </LightgalleryItem>
                    <img
                      className="img-fluid"
                      src="images/trainingvideos_page/port_img1.png"
                    ></img>
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://www.youtube.com/watch?v=3Xu8ONjbBeA&t=31s">
                      <img
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      ></img>
                    </LightgalleryItem>
                    <img
                      className="img-fluid"
                      src="images/trainingvideos_page/port_img2.png"
                    ></img>
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://www.youtube.com/watch?v=59v2ChrT81w">
                      <img
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      ></img>
                    </LightgalleryItem>
                    <img
                      className="img-fluid"
                      src="images/trainingvideos_page/port_img3.png"
                    ></img>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec2 inner_serv">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="h_s2_content">
                <h2
                  className="defaulth2"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  Explore Our Services{" "}
                </h2>
                <p className="defaultp">We have done so much more. </p>
              </div>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <LightgalleryItem src="https://www.youtube.com/watch?v=HZ4BG6xZyHU">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  className="img-fluid"
                  src="images/trainingvideos_page/forgiveandforget.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/educational-videos">
                      Explore Educational Videos
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1100"
              >
                <LightgalleryItem src="https://www.youtube.com/watch?v=_yXbRAcaqPo&list=PLFr89EPS-7DU3dEsDS1wE2hjGkHwPjyJt&index=5">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  className="img-fluid"
                  alt=""
                  src="images/trainingvideos_page/snowbird.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/brand-videos">Explore Brand Videos</Link>{" "}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <LightgalleryItem src="https://youtu.be/qgSPyEdZMhU">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  className="img-fluid"
                  src="images/trainingvideos_page/voipfone.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/social-media-videos">
                      Explore Social Media Ads
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec7">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2
                className="defaulth2"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                FAQs
              </h2>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    What goes into video production training?
                  </Accordion.Header>
                  <Accordion.Body>
                    We begin with scriptwriting and move on to storyboarding to
                    map out the script. Further, we add a voice-over to it. Then
                    comes the animation part where imagination comes to life.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    What if I have high demands? How will your training video
                    production be beneficial for me?
                  </Accordion.Header>
                  <Accordion.Body>
                    We are a team of skilled individuals producing a series of
                    videos simultaneously. We ensure that we’re always on
                    schedule without compromising on quality. Just give us a
                    timeline, and we will strategize accordingly.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    I want to know about the core elements of video editing.
                  </Accordion.Header>
                  <Accordion.Body>
                    To deliver the desired message in our video projects we use
                    a color scheme to set the tone for the entire scene. We then
                    add sound effects and lighting to bring out a significant
                    influence.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    How to get the best video productions?
                  </Accordion.Header>
                  <Accordion.Body>
                    Opt for the ones transforming your content through
                    informative and effective visual learning tools, simplifying
                    complex processes into a memorable format.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <CtaSec></CtaSec>
    </>
  );
}
