import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { LightgalleryItem } from "react-lightgallery";
import Slider from "react-slick";
import CtaSec from "../components/includes/CtaSec";
import MeetingBtn from "../components/includes/MeetingBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function CartoonAnimation() {
  document.title =
    " Cartoon Animation Production Company I  Connecting With All Age Groups";
  document.getElementsByTagName("META")[2].content =
    "Cartoon animation has the power to deliver any idea in the most interesting, exciting way. We will make you the perfect cartoon animation video. Click to read more.";
  var Portfolio_Slider = {
    slidesToShow: 1,
    infinite: true,
    dots: true,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "120px",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (window.jQuery(window).width() < 767) {
      window.jQuery(".res_slider").slick({
        dots: true,
      });
      window.jQuery(".res_slider2").slick();
    }

    Aos.init();
  });
  return (
    <>
      <section className="inner_sec1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h1 className="banner_h1">Cartoon Animation</h1>
              <p className="defaultp">
                If you’ve ever tried to understand a concept from YouTube,
                you’ve probably come across some cartoon animation videos. I’m
                willing to bet you watched them all the way through! This is
                because cartoon animation is basically like taking an idea and
                turning it into a moving and talking character. This video
                format can be super engaging if done correctly. Lucky for you,
                we know how to do it!
              </p>
              <div className="h_btn">
                <Link
                  data-aos="fade-up"
                  data-aos-duration="8600"
                  className="pop-btn white_btn"
                  to={"/contact"}
                >
                  Get started
                </Link>
                <a
                  href="javascript:void(Tawk_API.toggle())"
                  className="pop-btn black_btn chats"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Live Chat
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 banner_play">
              <LightgalleryItem src="https://www.youtube.com/watch?v=i2Ei8fEMWy8">
                <img className="play-btn-st" src="images/play.png" alt="" />
              </LightgalleryItem>
              <img
                className="img-fluid"
                src="images/cartoonanimation_page/s1right.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                className="img-fluid"
                src="images/cartoonanimation_page/1.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">Cartoons Aren’t Just For Kids</h2>
              <p className="defaultp">
                Whenever someone hears the word cartoon, they probably start
                thinking of Looney Tunes or Pink Panther – but that’s not the
                only type of cartoon that exists! Cartoons, in general, can be
                for adults too, and if the content is done right, they can
                appeal to both adults and kids at the same time.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec3 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">Visual Appeal of Cartoons</h2>
              <p className="defaultp">
                If you’re wondering why you should use a cartoon explainer video
                for your business, allow us to convince you. As a cartoon
                production company, we’ve made many types of cartoon videos, but
                the one thing that binds them is the visual appeal of cartoons!
                Regardless of the concept, cartoon animation can elevate your
                delivery to the next level.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                className="img-fluid"
                src="images/cartoonanimation_page/2.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="m_sec4 inner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">Short Clips of What We’ve Done</h2>
              <p className="defaultp">
                Take a look at some custom cartoon animation videos that we’ve
                made in the past for our clients.
              </p>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="images/mainservice-page/s4img1.png">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                {/* <LightgalleryItem></LightgalleryItem> */}

                <img
                  className="img-fluid"
                  src="images/mainservice-page/s4img1.png"
                ></img>
                <div className="s4_overlay">
                  <h4>HELLO</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="images/mainservice-page/s4img2.png">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  className="img-fluid"
                  src="images/mainservice-page/s4img2.png"
                ></img>
                <div className="s4_overlay">
                  <h4>HELLO</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="images/mainservice-page/s4img3.png">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>

                <img
                  className="img-fluid"
                  src="images/mainservice-page/s4img3.png"
                ></img>
                <div className="s4_overlay">
                  <h4>HELLO</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="images/mainservice-page/s4img4.png">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  className="img-fluid"
                  src="images/mainservice-page/s4img4.png"
                ></img>
                <div className="s4_overlay">
                  <h4>HELLO</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="images/mainservice-page/s4img5.png">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  className="img-fluid"
                  src="images/mainservice-page/s4img5.png"
                ></img>
                <div className="s4_overlay">
                  <h4>HELLO</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="images/mainservice-page/s4img6.png">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  className="img-fluid"
                  src="images/mainservice-page/s4img6.png"
                ></img>
                <div className="s4_overlay">
                  <h4>HELLO</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                className="img-fluid"
                src="images/cartoonanimation_page/3.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">The Power of Storytelling</h2>
              <p className="defaultp">
                We’re not just a cartoon animation studio; we are also great
                storytellers, and cartoons are a brilliant medium for that. If
                you want to tell a story with singing broccoli, dancing
                penguins, or a funkily dressed person, we’ve got you covered!
                Cartoons are easy to understand and get your point across to the
                audience seamlessly. If you want a lasting impact, a catchy
                cartoon is all you need.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='inner_sec3 zig_zag'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <h2 className='defaulth2'>Exploring Real-World</h2>
                    <p className='defaultp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit tortor sit amet lectus luctus auctor. Quisque hendrerit pellentesque urna, eu eleifend dolor ornare ac. Nullam ornare tincidunt erat, ut laoreet purus condimentum ut. Fusce feugiat ipsum nisi, sit amet commodo odio imperdiet eu. Mauris luctus consequat arcu quis vestibulum. Aenean laoreet, dui eu rhoncus placerat, dolor turpis euismod lorem, quis eleifend tortor eros id diam. Morbi a tincidunt ex. Cras augue dolor, molestie ut dapibus id, interdum vel felis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit tortor sit amet lectus luctus auctor. Quisque hendrerit pellentesque urna, eu eleifend dolor ornare ac. Nullam ornare tincidunt erat, ut laoreet purus condimentum ut. Fusce feugiat ipsum nisi, sit amet commodo odio imperdiet eu. Mauris luctus consequat arcu quis vestibulum. Aenean laoreet, dui eu rhoncus placerat, dolor turpis euismod lorem, quis eleifend tortor eros id diam. Morbi a tincidunt ex. Cras augue dolor, molestie ut dapibus id, interdum vel felis.</p>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <img className='img-fluid' src='images/innerservice-page/s3right.png'></img>
                </div>
            </div>
        </div>
    </section> */}
      <section className="m_sec2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">Animation Production Process</h2>
              <p className="defaultp">
                At our animation production company, we're the modern-day
                wizards, conjuring animated wonders. Our creative cauldron
                blends ideas, stirs innovation, and voila – captivating
                animations are born. Step into our world, where imagination
                takes center stage, and your vision transforms into whimsical
                realities!
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process">
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Concept & Story Creation</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Scriptwriting</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Storyboarding</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Style & Visual design</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Voiceover & Sound design</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process_gif res_slider2">
                <li className="">
                  <img
                    src="images/mainservice-page/process_1.gif"
                    alt="Process Gif"
                  ></img>
                </li>
                <li className="">
                  <img
                    src="images/mainservice-page/process_2.gif"
                    alt="Process Gif"
                  ></img>
                </li>
                <li className="">
                  <img
                    src="images/mainservice-page/process_3.gif"
                    alt="Process Gif"
                  ></img>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='inner_portfolio'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-lg-3 col-md-6 col-sm-12'>/
                    <h2 className='defaulth2'>Our Portfolio</h2>
                    <p className='defaultp'>We’re a cartoon animation maker that can craft creative cartoon animation services for your business. Take a look at our portfolio!</p>
                    <Link className='popup-btn white_btn' to={"/contact"}>Get started</Link>
                </div>
                <div className='col-lg-9 col-md-12 col-sm-12'>
                    <div className='portfolio_slider'>
                        <Slider {...Portfolio_Slider}>
                            <div className='m_s4_slide'>
                                <img className='play-btn-st' src="images/play.png" alt='' />
                                <img className='img-fluid' src='images/innerservice-page/port_img1.png'></img>
                            </div>
                            <div className='m_s4_slide'>
                                <img className='play-btn-st' src="images/play.png" alt='' />
                                <img className='img-fluid' src='images/innerservice-page/port_img1.png'></img>
                            </div>
                            <div className='m_s4_slide'>
                                <img className='play-btn-st' src="images/play.png" alt='' />
                                <img className='img-fluid' src='images/innerservice-page/port_img1.png'></img>
                            </div>
                            <div className='m_s4_slide'>
                                <img className='play-btn-st' src="images/play.png" alt='' />
                                <img className='img-fluid' src='images/innerservice-page/port_img1.png'></img>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
      <section className="h_sec2 inner_serv">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="h_s2_content">
                <h2
                  className="defaulth2"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  Other Services We Offer
                </h2>
                <p className="defaultp">
                  We make more than just cartoons. Look at some other types of
                  services we offer!
                </p>
              </div>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <LightgalleryItem src="">
                  {/* <video  className='img-fluid'  src="images/innerservice-page/other_serv1.png" muted autoPlay={"autoplay"} preload="auto" loop ></video> */}
                  <img
                    className="img-fluid"
                    src="images/innerservice-page/other_serv1.png"
                  ></img>
                </LightgalleryItem>
                <div className="s2_box_data">
                  <h5>Motion Graphics</h5>
                  <p>Visual inaction</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1100"
              >
                <LightgalleryItem src="">
                  {/* <video  className='img-fluid'  src="images/innerservice-page/other_serv2.png" muted autoPlay={"autoplay"} preload="auto" loop ></video> */}
                  <img
                    className="img-fluid"
                    src="images/innerservice-page/other_serv2.png"
                  ></img>
                </LightgalleryItem>
                <div className="s2_box_data">
                  <h5>3D Animation</h5>
                  <p>Realms brought to life</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <LightgalleryItem src="">
                  {/* <video  className='img-fluid'  src="images/innerservice-page/other_serv3.png" muted autoPlay={"autoplay"} preload="auto" loop ></video> */}
                  <img
                    className="img-fluid"
                    src="images/innerservice-page/other_serv3.png"
                  ></img>
                </LightgalleryItem>
                <div className="s2_box_data">
                  <h5>Whiteboard Videos</h5>
                  <p>Ideas vividly drawn</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CtaSec></CtaSec>
    </>
  );
}
