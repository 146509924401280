import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { LightgalleryItem } from "react-lightgallery";
import Slider from "react-slick";
import CtaSec from "../components/includes/CtaSec";
import Popup from "../components/includes/Popup";
import { Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
export default function ExplainerVideo3d() {
  document.title = "Adding Depth By 3D Animation Services I Lifelike Visuals";
  // document.getElementsByTagName("META")[2].content =
  //   "Transform your marketing with us. Get higher engagement and add popping visuals with 3D animation videos crafted by our expert animators. Get a quote now.";
  var Portfolio_Slider = {
    slidesToShow: 1,
    infinite: true,
    dots: true,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "120px",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "50px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (window.jQuery(window).width() < 767) {
      window.jQuery(".res_slider").slick({
        dots: true,
      });
      window.jQuery(".res_slider2").slick();
    }

    Aos.init();
  });
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.videojeeves.com/3d-animation-videos"
        />
        <meta
          name="description"
          content="Transform your marketing with us. Get higher engagement and add popping visuals with 3D animation videos crafted by our expert animators. Get a quote now."
        />
      </Helmet>
      <section className="inner_sec1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h1 className="banner_h1">3D Animation Videos</h1>
              <p className="defaultp">
                3D animation videos are perhaps the most versatile type of
                animated video that takes characters on an unexpected journey
                but with a fresh lens.
              </p>
              <p className="defaultp">
                Video Jeeves is a full package 3d animation company developing
                creative designs that enable customers to visualize and engage.
              </p>
              <div className="h_btn">
                <Link
                  data-aos="fade-up"
                  data-aos-duration="8600"
                  className="popup-btn white_btn"
                  to="/Contact-us"
                >
                  get a quote
                </Link>
                <Link
                  to="javascript:void(Tawk_API.toggle())"
                  className="pop-btn black_btn chats"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Live Chat
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 banner_play">
              <LightgalleryItem src="https://www.youtube.com/watch?v=i2Ei8fEMWy8">
                <img className="play-btn-st" src="images/play.png" alt="" />
              </LightgalleryItem>
              <img
                alt="3d Video Section 1"
                className="img-fluid"
                src="images/3dvideos-page/s1right.png"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                alt="3d Video Page 1"
                className="img-fluid"
                src="images/3dvideos-page/1.png"
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">A Blend of Animation and Reality </h2>
              <p className="defaultp mb-3">
                Realistic product designs to navigate the 3D world!
              </p>
              <p className="defaultp mb-3">
                The 3D explainer video demands digital adoption. So, to bring a
                change in the business dynamics we conceptualize ideas into
                video content.
              </p>
              <p className="defaultp mb-3">
                Our talented 3D video creators can guide you with all your
                product animation needs. They provide unique aspects with high
                animation quality to capture your brand’s essence and the target
                audience
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec3 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                Your go-to partner for creating 3D animations{" "}
              </h2>
              <p className="defaultp mb-3">
                We have got everything on our cards.
              </p>
              <p className="defaultp mb-3">
                Customers and potential clients are likely to be more impressed
                by an ad that looks professional, and product animations can
                help you do just that!
              </p>
              <p className="defaultp mb-3">
                We have a state-of-the-art animation studio delivering high-end
                3D projects. To bring the best possible results our 3D video
                creators design sound for every video allowing the audience to
                recall our concepts for a long time.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                alt="3d Video Section 2"
                className="img-fluid"
                src="images/3dvideos-page/2.png"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="m_sec4 inner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                3D Elements to Match Your Objectives{" "}
              </h2>
              <p className="defaultp">
                Popping visuals bring depth and realism to storytelling by using
                three-dimensional graphics.
              </p>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=f06hVeeH8AE">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    alt="3d Video Section 4"
                    className="img-fluid"
                    src="images/3dvideos-page/s4img1.png"
                  />
                  <div className="s4_overlay">
                    <h4>Bloody Marry</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=i2Ei8fEMWy8">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    alt="3d Video Section 4 img2"
                    className="img-fluid"
                    src="images/3dvideos-page/s4img2.png"
                  />
                  <div className="s4_overlay">
                    <h4>3D Characters</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=EAAkVBT229k">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    alt="3d Video Section 4 img3"
                    className="img-fluid"
                    src="images/3dvideos-page/s4img3.png"
                  />
                  <div className="s4_overlay">
                    <h4>Coolest Custom Printing</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=HZ4BG6xZyHU">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    alt="3d Video Section 4 img4"
                    className="img-fluid"
                    src="images/3dvideos-page/s4img4.png"
                  />
                  <div className="s4_overlay">
                    <h4>Forgive and Forget</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/m-uzJinTqgo?si=7_LFMDqPk8tpFk05">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    alt="3d Video Section 4 img5"
                    className="img-fluid"
                    src="images/3dvideos-page/s4img5.png"
                  />
                  <div className="s4_overlay">
                    <h4>Osiris Torado</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/v7QsmuvqZ-U?si=KhHYJ_ac-tepZAsR">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    alt="3d Video Section 4 img6"
                    className="img-fluid"
                    src="images/3dvideos-page/s4img6.png"
                  />
                  <div className="s4_overlay">
                    <h4>Zilla U</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag three-d">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                alt="3d Video Page 3 "
                className="img-fluid"
                src="images/3dvideos-page/3.png"
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                Captivating Animations to Stay on Top of the Competition
              </h2>
              <p className="defaultp mb-3">
                You are surely going to gain a competitive edge with our 3D
                animation services tailored for you.
              </p>
              <p className="defaultp mb-3">
                Showcase yourself like never before and let the customers
                explore every detail in a virtual space. From character
                development to adding animations we nail every frame of the
                video project.
              </p>
              <p className="defaultp mb-3">
                The team can turn any idea into life, ensuring the results
                exceed your expectations.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="m_sec2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">Stirring Innovation into Ideas</h2>
              <p className="defaultp">
                Step into our world, where imagination takes center stage!
              </p>
              <p className="defaultp">
                We're the modern-day wizards coming up with animated wonders.
                Our creative team blends ideas, stirs innovation, and voila! Now
                your vision has transformed into a reality!{" "}
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process">
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Concept & Story Creation</span>
                </li>
                <li className="">
                  <img
                    alt="3d Video Arrow"
                    src="images/mainservice-page/arrow.svg"
                  />
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Scriptwriting</span>
                </li>
                <li className="">
                  <img
                    alt="3d Video Arrow"
                    src="images/mainservice-page/arrow.svg"
                  />
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Storyboarding</span>
                </li>
                <li className="">
                  <img
                    alt="3d Video Arrow"
                    src="images/mainservice-page/arrow.svg"
                  />
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Style & Visual design</span>
                </li>
                <li className="">
                  <img
                    alt="3d Video Arrow"
                    src="images/mainservice-page/arrow.svg"
                  />
                </li>
                <li className="m_s2_box">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Voiceover & Sound design</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process_gif res_slider2">
                <li className="">
                  <img
                    alt="3d Video Proccess Gif"
                    src="images/mainservice-page/process_1.gif"
                  />
                </li>
                <li className="">
                  <img
                    alt="3d Video Proccess Gif"
                    src="images/mainservice-page/process_2.gif"
                  />
                </li>
                <li className="">
                  <img
                    alt="3d Video Proccess Gif"
                    src="images/mainservice-page/process_3.gif"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_portfolio">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h2 className="defaulth2">Our Portfolio</h2>
              <p className="defaultp">
                We’re a 3D animation company that offers quality services for
                your business. Take a look at our portfolio!
              </p>
              <Link className="popup-btn white_btn button-86" to="/Contact-us">
                Get started
              </Link>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="portfolio_slider">
                <Slider {...Portfolio_Slider}>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://www.youtube.com/watch?v=i2Ei8fEMWy8">
                      <img
                        className="play-btn-st"
                        src="images/play.png"
                        alt=""
                      />
                    </LightgalleryItem>
                    <img
                      alt="3d Video Port Img 1"
                      className="img-fluid"
                      src="images/3dvideos-page/port_img1.png"
                    />
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://www.youtube.com/watch?v=i2Ei8fEMWy8">
                      <img
                        className="play-btn-st"
                        src="images/play.png"
                        alt=""
                      />
                    </LightgalleryItem>
                    <img
                      alt="3d Video Port Img 2"
                      className="img-fluid"
                      src="images/3dvideos-page/port_img2.png"
                    />
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://youtu.be/v7QsmuvqZ-U?si=KhHYJ_ac-tepZAsR">
                      <img
                        className="play-btn-st"
                        src="images/play.png"
                        alt=""
                      />
                    </LightgalleryItem>
                    <img
                      alt="3d Video Port Img 3"
                      className="img-fluid"
                      src="images/3dvideos-page/port_img3.png"
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec2 inner_serv">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="h_s2_content">
                <h2
                  className="defaulth2"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  Other Services We Offer
                </h2>
                <p className="defaultp">
                  We make more than just 3D animation services. Look at some
                  other types of services we offer!
                </p>
              </div>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {/* <LightgalleryItem src="https://youtu.be/v7QsmuvqZ-U?si=KhHYJ_ac-tepZAsR">
                        <img className='play-btn-st' src="images/play.png" alt='' />
                    </LightgalleryItem> */}
                <LightgalleryItem src="https://youtu.be/AO5Gnv6ZyNg?si=pS88T4h6BhNep8r9">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  {/* <video  className='img-fluid'  src="images/3dvideos-page/other_serv1.png" muted autoPlay={"autoplay"} preload="auto" loop ></video> */}
                </LightgalleryItem>
                <img
                  alt="3d Video Port Img 2Hire"
                  className="img-fluid"
                  src="images/3dvideos-page/2hire.png"
                />
                <div className="s2_box_data">
                  <h5>
                    <Link to="/explainer-videos">Explore Explainer Video</Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1100"
              >
                <LightgalleryItem src="https://youtu.be/GP6uI0x47zM?si=cRxHXgugdmHsWljM">
                  {/* <video  className='img-fluid'  src="images/3dvideos-page/other_serv2.png" muted autoPlay={"autoplay"} preload="auto" loop ></video> */}
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt="3d Video Port Img InvestFly"
                  className="img-fluid"
                  src="images/3dvideos-page/investfly.png"
                />
                <div className="s2_box_data">
                  <h5>
                    <Link to="/motion-graphics-videos">
                      Explore Motion Graphics
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <LightgalleryItem src="https://youtu.be/ayb5RVhiDLU?si=rYHr6motuNZuPeVt">
                  {/* <video  className='img-fluid'  src="images/3dvideos-page/other_serv3.png" muted autoPlay={"autoplay"} preload="auto" loop ></video> */}
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt="WhiteBoard"
                  className="img-fluid"
                  src="images/3dvideos-page/whiteboard.png"
                />
                <div className="s2_box_data">
                  <h5>
                    <Link to="/white-board">Explore Whiteboard Videos</Link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec7">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2
                className="defaulth2"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                FAQs
              </h2>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    What is the difference between 2D and 3D videos?
                  </Accordion.Header>
                  <Accordion.Body>
                    The difference is for the number of dimensions used during
                    animations. 3D videos offer greater immersion and visual
                    appeal than 2D.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    How long does it take to create a 3D animation?{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    The timeline varies depending on the complexity of the
                    project. Simple animations may take a few days, while more
                    complex projects could take several weeks. Clear
                    communication helps set realistic timelines.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    What are the advantages of 3D animation videos?{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    3D animation videos enhance marketing efforts and allow
                    companies to expand their reach. They are excellent for
                    breaking down complex ideas and making it easier for the
                    audience to understand through visual representation.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Can 3D animations be used for marketing purposes?{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, 3D animations are highly effective for marketing. They
                    can be used in promotional videos, advertisements, and
                    product demonstrations to engage audiences and create a
                    lasting impact.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <CtaSec></CtaSec>
    </>
  );
}
