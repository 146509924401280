import React from "react";
import { Link } from "react-router-dom";

const MeetingBtn = () => {
  return (
    <div>
      <Link
        to={"https://meetings.hubspot.com/athar-g?"}
        target="blank"
        className="position-fixed meetingBtn"
      >
        Free Consultancy
      </Link>
      {/*  <Link
        target="blank"
        className="position-fixed top-0 z-9"
        to="https://api.whatsapp.com/send?phone=+16503923384&text=Hi there, can we schedule a call to discuss my project?"
      >
        Call Meawdawdawdawdawd
  </Link> */}
    </div>
  );
};

export default MeetingBtn;
