import { Routes, Route } from "react-router-dom";
import "./components/assets/css/bootstrap.min.css";
import "./components/assets/css/slick.css";
import "./components/assets/css/slick-theme.css";
import "./components/assets/css/style.css";
import "./components/assets/css/responsive.css";
import "./App.css";

// Includes
import Header from "./components/includes/Header";
// footer
import Footer from "./components/includes/Footer";
import "./components/assets/js/slick.js";
import ScrollToTop from "./components/includes/ScrollToTop";
import jQuery from "jquery";
import Home from "./pages/Home";
import CookiePageIp from "./components/includes/CookiesPageIp";
import About from "./pages/About.js";
import Contact from "./pages/Contact.js";
import Thankyou from "./pages/Thankyou.js";
import Term from "./pages/Term.js";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";
import MainService from "./pages/MainService.js";
import ExplainerVideo3d from "./pages/ExplainerVideo3d.js";
import MotionGraphics from "./pages/MotionGraphics.js";
import WhiteboardAnimation from "./pages/WhiteboardAnimation.js";
import ExplainerVideos from "./pages/ExplainerVideos.js";
import EducationalVideos from "./pages/EducationalVideos.js";
import CartoonAnimation from "./pages/CartoonAnimation.js";
import SocialMediaAds from "./pages/SocialMediaAds.js";
import BrandVideos from "./pages/BrandVideos.js";
import TrainingVideos from "./pages/TrainingVideos.js";
import StartupVideos from "./pages/StartupVideos.js";
import Portfolio from "./pages/Portfolio.js";
import Work from "./pages/Work.js";
import ErrorPage from "./pages/ErrorPage/ErrorPage.js";
import Agency from "./pages/Corporate.js";
import MeetingBtn from "./components/includes/MeetingBtn.js";
import Animation2d from "./pages/2dAnimationLP/Animation2d.js";
import WhatsNewLP from "./pages/WhatsNewLP/WhatsNewLP.js";
import AnimationConcept1 from "./pages/2dAnimationLP/AnimationConcept1.js";

window.jQuery = jQuery;

function App() {
  return (
    <ScrollToTop>
      <div>
        <MeetingBtn />
        <Header />
        <CookiePageIp />
        <Routes>
          <Route path="*" element={<ErrorPage />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/terms-and-conditions" element={<Term />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/serv" element={<MainService />} />
          <Route path="/3d-animation-videos" element={<ExplainerVideo3d />} />
          <Route path="/motion-graphics-videos" element={<MotionGraphics />} />
          <Route
            path="/whiteboard-animation"
            element={<WhiteboardAnimation />}
          />
          <Route path="/cartoon-animation" element={<CartoonAnimation />} />
          <Route path="/explainer-videos" element={<ExplainerVideos />} />
          <Route path="/educational-videos" element={<EducationalVideos />} />
          <Route path="/training-videos" element={<TrainingVideos />} />
          <Route path="/social-media-videos" element={<SocialMediaAds />} />
          <Route path="/brand-videos" element={<BrandVideos />} />
          {/* <Route path="/live-action-videos" element={<LiveActionVideos />} /> */}
          <Route path="/startup-videos" element={<StartupVideos />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/work" element={<Work />} />
          {/* <Route path="/Corporate" element={<Agency />} />
          <Route path="/Whats-New" element={<WhatsNewLP />} />
          <Route path="/2d-Animation" element={<Animation2d />} />
  <Route path="/AnimationConcept1" element={<AnimationConcept1 />} /> */}
        </Routes>
        <Footer />
      </div>
    </ScrollToTop>
  );
}

export default App;
