import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { LightgalleryItem } from "react-lightgallery";
import Slider from "react-slick";
import CtaSec from "../components/includes/CtaSec";
import { Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

export default function EducationalVideos() {
  document.title = "Educational Videos Experts| Empower Learning";
  // document.getElementsByTagName("META")[2].content =
  //   "Discover a world of educational excellence. We create educational videos for you that provide a seamless path for better understanding. Call now!";
  var Portfolio_Slider = {
    slidesToShow: 1,
    infinite: true,
    dots: true,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "120px",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "50px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (window.jQuery(window).width() < 767) {
      window.jQuery(".res_slider").slick({
        dots: true,
      });
      window.jQuery(".res_slider2").slick();
    }

    Aos.init();
  });
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.videojeeves.com/educational-videos"
        />
        <meta
          name="description"
          content="Discover a world of educational excellence. We create educational videos for you that provide a seamless path for better understanding. Call now!"
        />
      </Helmet>
      <section className="inner_sec1 videos">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h1 className="banner_h1">
                How About Getting Schooled In Style?{" "}
              </h1>
              <p className="defaultp">
                <b>Expand your brainpower with educational videos</b> <br />
                Get the complex concepts broken down into chunks. An educational
                video aims to present information in a visually engaging way.{" "}
                <br />
                Educational video production companies show mind-blowing
                animations and give viewers an experience like none other. It is
                an unlocking potential by serving all purposes of memorization
                with clarity, curiosity, and creativity{" "}
              </p>
              <div className="h_btn">
                <Link
                  data-aos="fade-up"
                  data-aos-duration="8600"
                  className="popup-btn white_btn"
                  to="/Contact-us"
                >
                  get a quote
                </Link>
                <a
                  href="javascript:void(Tawk_API.toggle())"
                  className="pop-btn black_btn chats"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Live Chat
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 banner_play">
              <LightgalleryItem src="https://www.youtube.com/watch?v=R8vSizQBdS0&list=PLFr89EPS-7DX5lkHOLrV9VdGCs7QUG0Hc&index=3">
                <img className="play-btn-st" src="images/play.png" alt="" />
              </LightgalleryItem>
              <img
                className="img-fluid"
                src="images/educationalvideos_page/s1right.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                alt=""
                className="img-fluid"
                src="images/educationalvideos_page/1.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                Let’s make learning fun again with educational videos{" "}
              </h2>
              <p className="defaultp">
                <b>Expand your brainpower with educational videos</b> <br />
                An educational video producer plays a vital role in opening
                people’s minds to think innovatively. Our educational videos
                aren't just a breath of fresh air; they are a tornado of
                excitement. <br />
                Audio-visual cues stirring the soul. Adding music, transitions,
                graphics and voiceover to deliver a professional video. The
                videos connect all dots etching itself into the memory forever.{" "}
                <br />
                Through the magic of our videos, minds are set free to explore,
                create, and innovate like never before. So, prepare to have your
                intellectual horizons expanded, and your imagination unleashed.
                Join us in this revolution where education becomes an
                electrifying thrill ride.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec3 zig_zag ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">Proving As A Valuable Tool </h2>
              <p className="defaultp">
                And when they say learning never looked this good, they
                literally mean it. <br />
                Scripting plays a great role. The educational videos begin with
                a well-crafted script helping other aspects of the video to get
                into the flow. It also offers an up-close view. Taking the
                audience on a tour of educational video production to show
                what’s coming up next. <br />
                We believe built-in branding is a portrayal that is not just
                desirable but essential for success. Sleek, helpful and
                trustworthy, that’s how an educational video producer wants to
                portray the brand{" "}
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                alt=""
                className="img-fluid"
                src="images/educationalvideos_page/2.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="m_sec4 inner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">An Intellectual Voyage</h2>
              <p className="defaultp">Balancing creatives and strategizing </p>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=HZ4BG6xZyHU&t=27s">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    alt="Video Thumbnail"
                    className="img-fluid"
                    src="images/educationalvideos_page/s4img1.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Forgive and Forget</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/k_FtN7w-neE">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    alt="Video Thumbnail"
                    className="img-fluid"
                    src="images/educationalvideos_page/s4img2.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>NCSU - 2D Explainer Video</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=CYm5nW-TB30">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    alt="Video Thumbnail"
                    className="img-fluid"
                    src="images/educationalvideos_page/s4img3.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>SMC Panduit</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/qgSPyEdZMhU">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    alt="Video Thumbnail"
                    className="img-fluid"
                    src="images/educationalvideos_page/s4img4.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Voipfone</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=59v2ChrT81w">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    alt="Video Thumbnail"
                    className="img-fluid"
                    src="images/educationalvideos_page/s4img5.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Wilbury</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=lqCRl2Ki-WE">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    alt=""
                    className="img-fluid"
                    src="images/educationalvideos_page/s4img6.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Yash Solutions</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                alt=""
                className="img-fluid"
                src="images/educationalvideos_page/3.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">Boosting Learning Experience</h2>
              <p className="defaultp">
                One click at a time to see the world with a new lens
                <br />
                Recording process involves delivery style with distinct vocal
                qualities. To record an educational video, our voice-over
                artists ensure best performance in a soundproof recording
                studio. <br />
                Priority scheduling of videos caters to faster turnaround time
                which is surely our thing. Priority scheduling is the roadmap to
                mastery. We sort tasks and are here mastering the chaos. Custom
                characters from sketch to saas, and plain to insane. Educational
                video production companies know the magic formula of creating
                custom characters. <br />
                You will also find some extra versions. Want a bit more? Well,
                then quirk shall meet quirkier. Tell us your vision and we will
                let knowledge and animations collide.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='inner_sec3 zig_zag'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <h2 className='defaulth2'>Exploring Real-World</h2>
                    <p className='defaultp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit tortor sit amet lectus luctus auctor. Quisque hendrerit pellentesque urna, eu eleifend dolor ornare ac. Nullam ornare tincidunt erat, ut laoreet purus condimentum ut. Fusce feugiat ipsum nisi, sit amet commodo odio imperdiet eu. Mauris luctus consequat arcu quis vestibulum. Aenean laoreet, dui eu rhoncus placerat, dolor turpis euismod lorem, quis eleifend tortor eros id diam. Morbi a tincidunt ex. Cras augue dolor, molestie ut dapibus id, interdum vel felis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit tortor sit amet lectus luctus auctor. Quisque hendrerit pellentesque urna, eu eleifend dolor ornare ac. Nullam ornare tincidunt erat, ut laoreet purus condimentum ut. Fusce feugiat ipsum nisi, sit amet commodo odio imperdiet eu. Mauris luctus consequat arcu quis vestibulum. Aenean laoreet, dui eu rhoncus placerat, dolor turpis euismod lorem, quis eleifend tortor eros id diam. Morbi a tincidunt ex. Cras augue dolor, molestie ut dapibus id, interdum vel felis.</p>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <img className='img-fluid' src='images/innerservice-page/s3right.png'></img>
                </div>
            </div>
        </div>
    </section> */}
      <section className="m_sec2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">Animation Production Process</h2>
              <p className="defaultp">
                At our animation production company, we're the modern-day
                wizards, conjuring animated wonders. Our creative cauldron
                blends ideas, stirs innovation, and voila – captivating
                animations are born. Step into our world, where imagination
                takes center stage, and your vision transforms into whimsical
                realities!
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process">
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Concept & Story Creation</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Scriptwriting</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Storyboarding</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Style & Visual design</span>
                </li>
                <li className="">
                  <img src="images/mainservice-page/arrow.svg" alt=""></img>
                </li>
                <li className="m_s2_box">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Voiceover & Sound design</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process_gif res_slider2">
                <li className="">
                  <img
                    alt="process gif"
                    src="images/mainservice-page/process_1.gif"
                  ></img>
                </li>
                <li className="">
                  <img
                    alt="process gif"
                    src="images/mainservice-page/process_2.gif"
                  ></img>
                </li>
                <li className="">
                  <img
                    alt="process gif"
                    src="images/mainservice-page/process_3.gif"
                  ></img>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_portfolio">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h2 className="defaulth2">Our Portfolio</h2>
              <p className="defaultp">
                We’re an animated educational video company that offers quality
                services for your business. Take a look at our portfolio!
              </p>
              <Link className="popup-btn white_btn button-86" to="/Contact-us">
                Get started
              </Link>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="portfolio_slider">
                <Slider {...Portfolio_Slider}>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://youtu.be/semVzZE8AHw?si=sz1fgXDqTbs8pDTV">
                      <img
                        alt="play button"
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      ></img>
                    </LightgalleryItem>
                    <img
                      alt="play button"
                      className="img-fluid"
                      src="images/educationalvideos_page/port_img1.png"
                    ></img>
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://www.youtube.com/watch?v=HZ4BG6xZyHU&t=27s">
                      <img
                        alt="play button"
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      ></img>
                    </LightgalleryItem>
                    <img
                      alt="play button"
                      className="img-fluid"
                      src="images/educationalvideos_page/port_img2.png"
                    ></img>
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://www.youtube.com/watch?v=CYm5nW-TB30">
                      <img
                        alt="play button"
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      ></img>
                    </LightgalleryItem>
                    <img
                      alt="play button"
                      className="img-fluid"
                      src="images/educationalvideos_page/port_img3.png"
                    ></img>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec2 inner_serv">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="h_s2_content">
                <h2
                  className="defaulth2"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  Explore Our Services{" "}
                </h2>
                <p className="defaultp">We have done so much more. </p>
              </div>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <LightgalleryItem src="https://youtu.be/H8jMo15hpCU?si=fnlbFdzZjtDZ_YrR">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt="Video Thumbnail"
                  className="img-fluid"
                  src="images/educationalvideos_page/detego.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/startup-videos">Explore Startup Videos </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1100"
              >
                <LightgalleryItem src="https://www.youtube.com/watch?v=_yXbRAcaqPo&list=PLFr89EPS-7DU3dEsDS1wE2hjGkHwPjyJt&index=5">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt="Video Thumbnail"
                  className="img-fluid"
                  src="images/educationalvideos_page/snowbird.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/brand-videos">Explore Brand Videos</Link>{" "}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <LightgalleryItem src="https://www.youtube.com/watch?v=7uPwJwfar8Y">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt="Video Thumbnail"
                  className="img-fluid"
                  src="images/educationalvideos_page/venturecapital.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/training-videos">Explore Training Videos</Link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec7">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2
                className="defaulth2"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                FAQs
              </h2>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    How do I get started with creating educational videos for my
                    organization?
                  </Accordion.Header>
                  <Accordion.Body>
                    To get started, simply reach out to our team. We will guide
                    you through the process, from defining your learning
                    objectives to choosing the right video format.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    How can I ensure my educational videos are engaging and
                    effective for learners?
                  </Accordion.Header>
                  <Accordion.Body>
                    We employ instructional design best practices, interactive
                    elements, and engaging visuals to create educational videos
                    that are both informative and captivating.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    What are some elements of a great educational video?{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    Make it useful and informative by adding easy to follow
                    teaching points. Deliver a clear message and ensure to keep
                    it engaging through characters.{" "}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Can you create custom educational videos tailored to our
                    specific curriculum or training materials?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, we can create custom educational videos that align with
                    your curriculum, training materials, and learning
                    objectives.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <CtaSec></CtaSec>
    </>
  );
}
