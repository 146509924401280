import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { LightgalleryItem } from "react-lightgallery";
import Slider from "react-slick";
import CtaSec from "../components/includes/CtaSec";
import { Accordion } from "react-bootstrap";
import Popup from "../components/includes/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

export default function SocialMediaAds() {
  document.title = "Exceptional Social Media Videos| Maximize Your Scroll";
  // document.getElementsByTagName("META")[2].content =
  //   "Step into the spotlight with social media videos. Discover content that is beyond a memorable experience to ignite your social engagement. Give a call now!";
  var Portfolio_Slider = {
    slidesToShow: 1,
    infinite: true,
    dots: true,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "120px",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "50px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    window.jQuery(document).ready(function () {
      window.jQuery(".myBtn").click(function () {
        var dots = window.jQuery(".dots");
        var moreText = window.jQuery(".more");
        var btnText = window.jQuery(".myBtn");

        if (dots.css("display") === "none") {
          dots.css("display", "inline");
          btnText.html("Read more");
          moreText.css("display", "none");
        } else {
          dots.css("display", "none");
          btnText.html("Read less");
          moreText.css("display", "inline");
        }
      });
    });
    if (window.jQuery(window).width() < 767) {
      window.jQuery(".res_slider").slick({
        dots: true,
      });
      window.jQuery(".res_slider2").slick();
    }

    Aos.init();
  });
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.videojeeves.com/social-media-videos"
        />
        <meta
          name="description"
          content="Step into the spotlight with social media videos. Discover content that is beyond a memorable experience to ignite your social engagement. Give a call now!"
        />
      </Helmet>
      <section className="inner_sec1 videos">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h1 className="banner_h1">Driving Digital Success</h1>
              <p className="defaultp">
                Videos on social media generate 1200% more shares than text and
                images combined (Forbes). Creative and well-executed social ads
                skyrocket your brand’s credibility and awareness.
              </p>
              <p className="defaultp">
                So, let our social media ads be the game-changer for your
                customer’s feed.
              </p>
              <div className="h_btn">
                <Link
                  data-aos="fade-up"
                  data-aos-duration="8600"
                  className="popup-btn white_btn"
                  to="/Contact-us"
                >
                  get a quote
                </Link>
                <a
                  href="javascript:void(Tawk_API.toggle())"
                  className="pop-btn black_btn chats"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Live Chat
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 banner_play">
              <LightgalleryItem src="https://www.youtube.com/watch?v=i2Ei8fEMWy8">
                <img className="play-btn-st" src="images/play.png" alt="" />
              </LightgalleryItem>
              <img
                className="img-fluid"
                src="images/socialmedia-ad_page/s1right.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <img
                className="img-fluid"
                src="images/socialmedia-ad_page/1.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                Social Media Videos Serving All Your Brand Verticals
              </h2>
              <p className="defaultp mb-3">
                Life is short for still images so let's add a dose of videos.
              </p>
              <p className="defaultp mb-3">
                Video Jeeves crafts a cinematic universe where every frame is a
                brushstroke of brilliance.
              </p>
              <p className="defaultp mb-3">
                Social media videos can become your cohesive voice because we
                work together to bring something new to the table. The team
                doesn't just develop a creative direction for it; they sculpt it
                into a masterpiece that redefines what's possible.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec3 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                Social Media Animations: Hyping Up Your Brand
              </h2>
              <p className="defaultp mb-3">
                Our social ads will make your feed more animated than a kid’s
                cartoon.
              </p>
              <p className="defaultp mb-3">
                Imagine your feed as a canvas and our animations as a colorful
                palette that doesn't just add life.
              </p>
              <p className="defaultp mb-3">
                Social media videos connect with what you do and what you stand
                for compellingly and authentically. We capture key messages and
                scenes to condense a 1–2-minute video.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <img
                alt="Social Media Ads 2"
                className="img-fluid"
                src="images/socialmedia-ad_page/2.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="m_sec4 inner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                Creating a Distinctive Brand Personal
              </h2>
              <p className="defaultp">
                With a blend of creativity, we spark interest through animated
                videos that will take your brand beyond automation.
              </p>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=CYm5nW-TB30&t=1s">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/socialmedia-ad_page/s4img1.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>SMC Panduit</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=EAAkVBT229k">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/socialmedia-ad_page/s4img2.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Coolest Custom Printing</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/AO5Gnv6ZyNg?si=pS88T4h6BhNep8r9">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/socialmedia-ad_page/s4img3.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>2Hire</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/_yXbRAcaqPo?si=hp4QDkUiJzCKhBnk">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/socialmedia-ad_page/s4img4.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Snowbird Investor</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/qgSPyEdZMhU">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/socialmedia-ad_page/s4img5.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Voipfone</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=59v2ChrT81w">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/socialmedia-ad_page/s4img6.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Wilbury</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <img
                className="img-fluid"
                src="images/socialmedia-ad_page/3.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                Extracting Creativity from Every Pixel{" "}
              </h2>
              <p className="defaultp mb-3">
                Learn, discover, and get to know stories. Because this is a
                match made in heaven.
              </p>
              <p className="defaultp mb-3">
                Video Jeeves creates high-quality social media videos to capture
                your audience's attention. We don't just display creativity; we
                pay attention to even the smallest detail to bring out that "WoW
                factor."
              </p>
              <p className="defaultp mb-3">
                The social media animations will perfectly align with your
                brand's message and let your brand appear bolder than ever
                before."
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='inner_sec3 zig_zag'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <h2 className='defaulth2'>Exploring Real-World</h2>
                    <p className='defaultp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit tortor sit amet lectus luctus auctor. Quisque hendrerit pellentesque urna, eu eleifend dolor ornare ac. Nullam ornare tincidunt erat, ut laoreet purus condimentum ut. Fusce feugiat ipsum nisi, sit amet commodo odio imperdiet eu. Mauris luctus consequat arcu quis vestibulum. Aenean laoreet, dui eu rhoncus placerat, dolor turpis euismod lorem, quis eleifend tortor eros id diam. Morbi a tincidunt ex. Cras augue dolor, molestie ut dapibus id, interdum vel felis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit tortor sit amet lectus luctus auctor. Quisque hendrerit pellentesque urna, eu eleifend dolor ornare ac. Nullam ornare tincidunt erat, ut laoreet purus condimentum ut. Fusce feugiat ipsum nisi, sit amet commodo odio imperdiet eu. Mauris luctus consequat arcu quis vestibulum. Aenean laoreet, dui eu rhoncus placerat, dolor turpis euismod lorem, quis eleifend tortor eros id diam. Morbi a tincidunt ex. Cras augue dolor, molestie ut dapibus id, interdum vel felis.</p>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <img className='img-fluid' src='images/innerservice-page/s3right.png'></img>
                </div>
            </div>
        </div>
    </section> */}
      <section className="m_sec2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">Stirring innovation into ideas</h2>
              <p className="defaultp mb-3">
                Step into our world, where imagination takes center stage!
              </p>
              <p className="defaultp">
                We're the modern-day wizards coming up with animated wonders.
                Our creative team blends ideas, stirs innovation, and voila! Now
                your vision has transformed into a reality!
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process">
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Concept & Story Creation</span>
                </li>
                <li className="">
                  <img alt="" src="images/mainservice-page/arrow.svg"></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Scriptwriting</span>
                </li>
                <li className="">
                  <img alt="" src="images/mainservice-page/arrow.svg"></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Storyboarding</span>
                </li>
                <li className="">
                  <img alt="" src="images/mainservice-page/arrow.svg"></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Style & Visual design</span>
                </li>
                <li className="">
                  <img alt="" src="images/mainservice-page/arrow.svg"></img>
                </li>
                <li className="m_s2_box">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Voiceover & Sound design</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process_gif res_slider2">
                <li className="">
                  <img alt="" src="images/mainservice-page/process_1.gif"></img>
                </li>
                <li className="">
                  <img alt="" src="images/mainservice-page/process_2.gif"></img>
                </li>
                <li className="">
                  <img alt="" src="images/mainservice-page/process_3.gif"></img>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_portfolio">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h2 className="defaulth2">Our Portfolio</h2>
              <p className="defaultp">
                We’re a social media animations company that offers quality
                services for your business. Take a look at our portfolio!
              </p>
              <Link className="popup-btn white_btn button-86" to="/Contact-us">
                Get started
              </Link>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="portfolio_slider">
                <Slider {...Portfolio_Slider}>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://youtu.be/H8jMo15hpCU?si=fnlbFdzZjtDZ_YrR">
                      <img
                        alt=""
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      ></img>
                    </LightgalleryItem>
                    <img
                      alt=""
                      className="img-fluid"
                      src="images/socialmedia-ad_page/port_img1.png"
                    ></img>
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://youtu.be/H8jMo15hpCU?si=fnlbFdzZjtDZ_YrR">
                      <img
                        alt=""
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      ></img>
                    </LightgalleryItem>
                    <img
                      alt=""
                      className="img-fluid"
                      src="images/socialmedia-ad_page/port_img2.png"
                    ></img>
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://youtu.be/H8jMo15hpCU?si=fnlbFdzZjtDZ_YrR">
                      <img
                        alt=""
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      ></img>
                    </LightgalleryItem>
                    <img
                      alt=""
                      className="img-fluid"
                      src="images/socialmedia-ad_page/port_img3.png"
                    ></img>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec2 inner_serv">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="h_s2_content">
                <h2
                  className="defaulth2"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  Explore Our Services{" "}
                </h2>
                <p className="defaultp">We have done so much more. </p>
              </div>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <LightgalleryItem src="https://www.youtube.com/watch?v=_yXbRAcaqPo&list=PLFr89EPS-7DU3dEsDS1wE2hjGkHwPjyJt&index=5">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt=""
                  className="img-fluid"
                  src="images/socialmedia-ad_page/snowbird.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/brand-videos">Explore Brand Videos</Link>{" "}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1100"
              >
                <LightgalleryItem src="https://www.youtube.com/watch?v=7uPwJwfar8Y">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt=""
                  className="img-fluid"
                  src="images/socialmedia-ad_page/venturecapital.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/training-videos">Explore Training Videos</Link>{" "}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <LightgalleryItem src="https://www.youtube.com/watch?v=lqCRl2Ki-WE">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt=""
                  className="img-fluid"
                  src="images/socialmedia-ad_page/yashsolutions.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/startup-videos">Explore Startup Videos</Link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec7">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2
                className="defaulth2"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                FAQs
              </h2>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    How much does social media marketing cost?
                  </Accordion.Header>
                  <Accordion.Body>
                    First, you need to know your target audience and set
                    achievable goals. Running social ads can result in faster
                    growth but its cost mainly depends on the content strategy
                    and the social media ROI.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    How long does it take to make an animation for a social
                    media video?
                  </Accordion.Header>
                  <Accordion.Body>
                    It will be difficult to assume but it takes about 5 to 7
                    weeks to prepare a 60-second animation video. And if your
                    team is facing a few issues then it will surely take some
                    more time to deliver the project.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    What are the benefits of social media video production?
                  </Accordion.Header>
                  <Accordion.Body>
                    Professional social media videos reflect positively on your
                    brand and portray your message in a powerful way. It makes
                    your videos visually appealing and aligns with the brand’s
                    identity.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    How do you guys execute video projects?
                  </Accordion.Header>
                  <Accordion.Body>
                    Our team works with a clear vision and thus, executes each
                    and everything with a precise process. As experts, we
                    extract what exactly is needed for your animation.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <CtaSec></CtaSec>
    </>
  );
}
