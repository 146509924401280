import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { LightgalleryItem } from "react-lightgallery";
import Slider from "react-slick";
import CtaSec from "../components/includes/CtaSec";
import Popup from "../components/includes/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

export default function MotionGraphics() {
  document.title =
    "Offering Fine Quality Motion Graphic Videos | Artistry In Motion";
  // document.getElementsByTagName("META")[2].content =
  //   "Step into a world of visuals with motion graphic wonders. Our videos transform ideas into compelling narratives, to engage and inspire. Get a quote now!";
  var Portfolio_Slider = {
    slidesToShow: 1,
    infinite: true,
    dots: true,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "120px",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "50px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (window.jQuery(window).width() < 767) {
      window.jQuery(".res_slider").slick({
        dots: true,
      });
      window.jQuery(".res_slider2").slick();
    }

    Aos.init();
  });
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.videojeeves.com/Motion-Graphic"
        />
        <meta
          name="description"
          content="Step into a world of visuals with motion graphic wonders. Our videos transform ideas into compelling narratives, to engage and inspire. Get a quote now!"
        />
      </Helmet>
      <section className="inner_sec1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h1 className="banner_h1">Motion Graphics</h1>
              <p className="defaultp">
                From web design to UI and branding to marketing, Video Jeeves’
                motion graphics services will make your audience say wow! Motion
                graphics are different from animations in the sense that they
                make graphic elements move a bit, so instead of a full video,
                they’ll just make your content more enticing to look at. Motion
                design is a great way to make your content pop and sizzle rather
                than stay stagnant like a sloth!
              </p>
              <div className="h_btn">
                <Link
                  data-aos="fade-up"
                  data-aos-duration="8600"
                  className="popup-btn white_btn"
                  to="/Contact-us"
                >
                  get a quote
                </Link>
                <Link
                  to="javascript:void(Tawk_API.toggle())"
                  className="pop-btn black_btn chats"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Live Chat
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 banner_play">
              <LightgalleryItem src="https://youtu.be/6PbO3QO-RHA?si=aoF12dfv5QlwsQ1M">
                <img className="play-btn-st" src="images/play.png" alt="" />
              </LightgalleryItem>
              <img
                alt="Section 1 Right"
                className="img-fluid"
                src="images/motiongraphics-page/s1right.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                alt="Section Motion Graphic 1"
                className="img-fluid"
                src="images/motiongraphics-page/1.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">Marketing and Advertising</h2>
              <p className="defaultp">
                Are you bored by the same old graphs and charts? Trust us, so is
                your audience. If you want to create an eye-catching ad
                campaign, motion designers can help you incorporate movement in
                your content, which can then help you promote your product in an
                engaging way. You can also promote your brand using logo motion
                graphics if you find a signature style of custom motion graphics
                that you think works best for you and your business.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec3 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">3D Motion Graphics</h2>
              <p className="defaultp">
                Similar to animation, motion graphics don’t always have to be
                three-dimensional! Our motion graphic designer is skilled in
                Adobe motion graphics and can pretty much come up with anything
                that you can dream of. This style is particularly helpful for
                software development and UI (user interface) designing, so if
                you have an app in mind, hit us up! Boring isn’t in our
                vocabulary, but brilliant is – are you ready to make your
                content legendary?
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                alt="Section Motion Graphic 2"
                className="img-fluid"
                src="images/motiongraphics-page/2.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="m_sec4 inner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">Short Clips of What We’ve Done</h2>
              <p className="defaultp">
                Take a look at some motion graphics and animation content that
                our motion graphics artist has made in the past for our (very
                satisfied) clients.
              </p>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=semVzZE8AHw">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    alt="Section4 Motion Graphic 1"
                    className="img-fluid"
                    src="images/motiongraphics-page/s4img1.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Compliance Tech</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/GP6uI0x47zM?si=cRxHXgugdmHsWljM">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    alt="Section4 Motion Graphic 2"
                    className="img-fluid"
                    src="images/motiongraphics-page/s4img2.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Invest Fly </h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/TUC44cAayOY?si=cUHeogkCB0AnYrzs">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    alt="Section4 Motion Graphic 3"
                    className="img-fluid"
                    src="images/motiongraphics-page/s4img3.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Municipal Clerks</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=k_FtN7w-neE">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    alt="Section4 Motion Graphic 4"
                    className="img-fluid"
                    src="images/motiongraphics-page/s4img4.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>NCSU</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/qgSPyEdZMhU">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    alt="Section4 Motion Graphic 5"
                    className="img-fluid"
                    src="images/motiongraphics-page/s4img5.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Voipfone</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=CqPMdDyuroY">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                  <img
                    alt="Section4 Motion Graphic 6"
                    className="img-fluid"
                    src="images/motiongraphics-page/s4img6.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Wilbury</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                alt="Section Motion Graphic 3"
                className="img-fluid"
                src="images/motiongraphics-page/3.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">Corporate Presentations</h2>
              <p className="defaultp">
                Look, we’ve all had to sit through a tedious presentation that
                has zero smooth transitions and a lot of chunky text on the
                screen. And we’ve all thought that’s an awful presentation that
                could lull you to sleep after 3 cups of coffee. Your content
                doesn’t have to be like that! Hit us up and we’ll make a
                brilliant presentation with motion graphics that will leave your
                viewers awestruck, make them excited about your concept and wish
                they were the ones giving the presentation!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="m_sec2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">Animation Production Process</h2>
              <p className="defaultp">
                At our animation production company, we're the modern-day
                wizards, conjuring animated wonders. Our creative cauldron
                blends ideas, stirs innovation, and voila – captivating
                animations are born. Step into our world, where imagination
                takes center stage, and your vision transforms into whimsical
                realities!
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process">
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Concept & Story Creation</span>
                </li>
                <li className="">
                  <img
                    alt="Arrow"
                    src="images/mainservice-page/arrow.svg"
                  ></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Scriptwriting</span>
                </li>
                <li className="">
                  <img
                    alt="Arrow"
                    src="images/mainservice-page/arrow.svg"
                  ></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Storyboarding</span>
                </li>
                <li className="">
                  <img
                    alt="Arrow"
                    src="images/mainservice-page/arrow.svg"
                  ></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Style & Visual design</span>
                </li>
                <li className="">
                  <img
                    alt="Arrow"
                    src="images/mainservice-page/arrow.svg"
                  ></img>
                </li>
                <li className="m_s2_box">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Voiceover & Sound design</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process_gif res_slider2">
                <li className="">
                  <img
                    alt="Process Gif"
                    src="images/mainservice-page/process_1.gif"
                  ></img>
                </li>
                <li className="">
                  <img
                    alt="Process Gif"
                    src="images/mainservice-page/process_2.gif"
                  ></img>
                </li>
                <li className="">
                  <img
                    alt="Process Gif"
                    src="images/mainservice-page/process_3.gif"
                  ></img>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_portfolio">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h2 className="defaulth2">Our Portfolio</h2>
              <p className="defaultp">
                We’re a motion graphics animation studio that offers quality
                services for your business. Take a look at our portfolio!
              </p>
              <Link className="popup-btn white_btn button-86" to="/Contact-us">
                Get started
              </Link>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="portfolio_slider">
                <Slider {...Portfolio_Slider}>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://youtu.be/GP6uI0x47zM?si=cRxHXgugdmHsWljM">
                      <img
                        className="play-btn-st"
                        src="images/play.png"
                        alt=""
                      />
                    </LightgalleryItem>
                    <img
                      alt="port img1"
                      className="img-fluid"
                      src="images/motiongraphics-page/port_img1.png"
                    />
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://youtu.be/TUC44cAayOY?si=cUHeogkCB0AnYrzs">
                      <img
                        className="play-btn-st"
                        src="images/play.png"
                        alt=""
                      />
                    </LightgalleryItem>
                    <img
                      alt="port img2"
                      className="img-fluid"
                      src="images/motiongraphics-page/port_img2.png"
                    ></img>
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://youtu.be/qgSPyEdZMhU">
                      <img
                        className="play-btn-st"
                        src="images/play.png"
                        alt=""
                      />
                    </LightgalleryItem>
                    <img
                      alt="port img3"
                      className="img-fluid"
                      src="images/motiongraphics-page/port_img3.png"
                    ></img>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec2 inner_serv">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="h_s2_content">
                <h2
                  className="defaulth2"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  Other Services We Offer
                </h2>
                <p className="defaultp">
                  We make more than just motion graphics. Look at some other
                  types of services we offer!
                </p>
              </div>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <LightgalleryItem src="https://www.youtube.com/watch?v=CYm5nW-TB30&t=1s">
                  {/* <video  className='img-fluid'  src="images/motiongraphics-page/other_serv1.png" muted autoPlay={"autoplay"} preload="auto" loop ></video> */}
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  className="img-fluid"
                  alt="Motion Graphic SMC"
                  src="images/motiongraphics-page/smc.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/explainer-videos">Explore Explainer Video</Link>
                  </h5>
                  {/* <p>Stories in motion</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1100"
              >
                <LightgalleryItem src="https://youtu.be/ayb5RVhiDLU?si=rYHr6motuNZuPeVt">
                  {/* <video  className='img-fluid'  src="images/motiongraphics-page/other_serv2.png" muted autoPlay={"autoplay"} preload="auto" loop ></video> */}
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt="Motion Graphic White Board"
                  className="img-fluid"
                  src="images/motiongraphics-page/whiteboard.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/white-board">Explore Whiteboard Videos</Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <LightgalleryItem src="https://www.youtube.com/watch?v=i2Ei8fEMWy8">
                  {/* <video  className='img-fluid'  src="images/motiongraphics-page/other_serv3.png" muted autoPlay={"autoplay"} preload="auto" loop ></video> */}
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt="Motion Graphic White Board"
                  className="img-fluid"
                  src="images/motiongraphics-page/3d.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/3d-videos">Explore 3D Animation</Link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CtaSec></CtaSec>
    </>
  );
}
