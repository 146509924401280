import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";

export default function Header() {
  useEffect(() => {
    window.jQuery(document).ready(function () {
      window.jQuery(document).scroll(function () {
        var top_barr = window.jQuery("header");
        if (window.jQuery(window).scrollTop() > 200) {
          top_barr.addClass("fixed-top");
        } else {
          top_barr.removeClass("fixed-top");
        }
      });
    });
    window.jQuery(document).ready(function () {
      window.jQuery(".dropdown-menu li").click(function (e) {
        e.preventDefault();
        window.jQuery(".dropdown-menu").addClass("active");
        console.log("csezrf");
      });
    });

    window.jQuery(function () {
      var app = (function () {
        var body = undefined;
        var menu = undefined;
        var menuItems = undefined;
        var init = function init() {
          body = document.querySelector("body");
          menu = document.querySelector(".menu-icon");
          menuItems = document.querySelectorAll(".nav__list-item");
          applyListeners();
        };
        var applyListeners = function applyListeners() {
          menu.addEventListener("click", function () {
            return toggleClass(body, "nav-active");
          });
        };
        var toggleClass = function toggleClass(element, stringClass) {
          if (element.classList.contains(stringClass))
            element.classList.remove(stringClass);
          else element.classList.add(stringClass);
        };
        init();
      })();
      function openNav() {
        document.getElementById("mySidenav").style.display = "block";
        document.getElementById("main").style.marginLeft = "250px";
      }
      function closeNav() {
        document.getElementById("mySidenav").style.display = "none";
        document.getElementById("main").style.marginLeft = "0";
      }

      // eslint-disable-next-line no-unused-expressions
      window.jQuery(".menu_dd").hide(),
        window.jQuery(".arrow_down + i").click(function () {
          // eslint-disable-next-line no-unused-expressions
          window.jQuery(".menu_dd").not(window.jQuery(this).next()).hide(),
            window.jQuery(this).next().stop(!0, !0).slideToggle();
        });

      window.jQuery(".hover-target-close").click(function () {
        // eslint-disable-next-line no-unused-expressions
        window.jQuery("body").removeClass("nav-active"),
          window.jQuery(".overlay-bg").css("display", "none"),
          window.jQuery(".popupform-main").removeClass("active");
      });

      window.jQuery(".slider").not(".slick-initialized").slick();

      window.jQuery(document).ready(function () {
        window.jQuery(".nav-link").hover(function () {
          window.jQuery(".dropdown").removeClass("active");
          //  window.jQuery(".tab").addClass("active"); // instead of this do the below
          window.jQuery(".dropdown").addClass("active");
        });
      });
    });
    window.jQuery(document).ready(function () {
      window.jQuery(".navbar-nav li a").click(function (event) {
        window.jQuery(".navbar-nav li a ").removeClass("active"); //Remove any "active" class
        window.jQuery(".navbar-nav li", this).addClass("active"); //Add "active" class to selected tab //
        // window.jQuery(activeTab).show(); //Fade in the active content
      });
    });

    window.jQuery(".nav-item a").click(function () {
      window.jQuery(".popupform-main").removeClass("active");
    });

    window.jQuery(".nav-link").click(function () {
      window.jQuery(".popupform-main").removeClass("active");
    });

    window.jQuery(".navlink-drop").hover(function () {
      window.jQuery(".dropdown-menu").addClass("active");
    });
    window.jQuery(".ddl_li").click(function () {
      window.jQuery(".dropdown-menu").removeClass("active");
    });
  });

  return (
    <>
      {/*     
<div className="overlay">
	<div className="overlayDoor"></div>
	<div className="overlayContent">
		<div className="loader"> */}
      {/* <div className="inner"></div> */}
      {/* <img src='images/logo-icon.png' alt=''></img>
		</div>
		
	</div>
</div> */}

      <header>
        <div className="container-fluid">
          <div className="row">
            <nav className="navbar  navbar-expand-lg ">
              <div className="container-fluid">
                <a className="navbar-brand" href="/">
                  <img
                    alt="Logo"
                    className="img-fluid"
                    src="images/logoNew.png"
                  ></img>
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="fas fa-bars"></i>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mr-auto w-100 justify-content-end">
                    <li className="nav-item active">
                      <Link className="nav-link" to="/work">
                        WORK
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className="nav-link navlink-drop p-0"
                        id="service_ddl"
                        data-toggle="dropdown"
                        to=""
                      >
                        Services
                        <ul className="dropdown-menu">
                          <h4 className="w-100">Video Use Case</h4>
                          <hr className=""></hr>

                          <div className="ddl_li">
                            <Link className="nav-link" to="/explainer-videos">
                              <div className="nav_icon">
                                <LazyLoad>
                                  <img
                                    alt=""
                                    className=""
                                    src="images/explainervideos_icon.png"
                                  ></img>
                                </LazyLoad>
                              </div>{" "}
                              <div>
                                {" "}
                                <span>Explainer Videos</span>{" "}
                                <p>
                                  {" "}
                                  The attractive videos use colorful visuals to
                                  simplify storytelling.
                                </p>
                              </div>
                            </Link>
                          </div>
                          <div className="ddl_li">
                            <Link className="nav-link" to="educational-videos">
                              <div className="nav_icon">
                                <LazyLoad>
                                  <img
                                    alt=""
                                    className=""
                                    src="images/educationalvideos_icon.png"
                                  ></img>
                                </LazyLoad>
                              </div>
                              <div>
                                {" "}
                                <span>Educational Videos</span>{" "}
                                <p>
                                  {" "}
                                  It makes learning fun again and gives viewers
                                  an experience like none other.
                                </p>
                              </div>
                            </Link>
                          </div>
                          <div className="ddl_li">
                            <Link className="nav-link" to="training-videos">
                              <div className="nav_icon">
                                <LazyLoad>
                                  <img
                                    alt=""
                                    className=""
                                    src="images/trainingvideos_icon.png"
                                  ></img>
                                </LazyLoad>
                              </div>{" "}
                              <div>
                                {" "}
                                <span>Training Videos</span>{" "}
                                <p>
                                  {" "}
                                  A shortcut serving with all you need. The
                                  visual and auditory elements merge seamlessly.
                                </p>
                              </div>
                            </Link>
                          </div>
                          <div className="ddl_li">
                            <Link className="nav-link" to="brand-videos">
                              <div className="nav_icon">
                                <LazyLoad>
                                  <img
                                    alt=""
                                    className=""
                                    src="images/brandvideos_icon.png"
                                  ></img>
                                </LazyLoad>
                              </div>{" "}
                              <div>
                                {" "}
                                <span>brand videos</span>{" "}
                                <p>
                                  {" "}
                                  Combining technical expertise and creative
                                  vision to let you get closer to your goals.
                                </p>
                              </div>
                            </Link>
                          </div>
                          <div className="ddl_li">
                            <Link className="nav-link" to="social-media-videos">
                              <div className="nav_icon">
                                <LazyLoad>
                                  <img
                                    alt=""
                                    className=""
                                    src="images/socialmedia_icon.png"
                                  ></img>
                                </LazyLoad>
                              </div>{" "}
                              <div>
                                {" "}
                                <span>Social Media Videos</span>{" "}
                                <p>
                                  {" "}
                                  Making your feed fun and extracting creativity
                                  from every pixel to resonate with your brand.
                                </p>
                              </div>
                            </Link>
                          </div>

                          <h4 className="w-100">Video Type</h4>
                          <hr className="w-100"></hr>
                          <div className="ddl_li">
                            <Link className="nav-link" to="3d-animation-videos">
                              <div className="nav_icon">
                                <LazyLoad>
                                  <img
                                    alt=""
                                    className=""
                                    src="images/3d_icon.png"
                                  ></img>
                                </LazyLoad>
                              </div>{" "}
                              <div>
                                {" "}
                                <span>3D Animation Videos</span>{" "}
                                <p>
                                  {" "}
                                  Popping visuals bringing depth and realism to
                                  storytelling by using three-dimensional
                                  graphics.
                                </p>
                              </div>
                            </Link>
                          </div>
                          <div className="ddl_li">
                            <Link className="nav-link" to="motion-graphics-videos">
                              <div className="nav_icon">
                                <LazyLoad>
                                  <img
                                    alt=""
                                    className=""
                                    src="images/motiongraphics_icon.png"
                                  ></img>
                                </LazyLoad>
                              </div>{" "}
                              <div>
                                {" "}
                                <span>Motion Graphics Videos</span>{" "}
                                <p>
                                  {" "}
                                  The visual symphonies bring data and ideas to
                                  life in a dynamic way.
                                </p>
                              </div>
                            </Link>
                          </div>
                          <div className="ddl_li">
                            <Link
                              className="nav-link"
                              to="whiteboard-animation"
                            >
                              <div className="nav_icon">
                                <LazyLoad>
                                  <img
                                    alt=""
                                    className=""
                                    src="images/whiteboard_icon.png"
                                  ></img>
                                </LazyLoad>
                              </div>{" "}
                              <div>
                                {" "}
                                <span>Whiteboard Animation</span>{" "}
                                <p>
                                  {" "}
                                  Animated canvases giving life to ideas and
                                  making complex concepts engaging.
                                </p>
                              </div>
                            </Link>
                          </div>
                          {/* 
                          <div className="ddl_li">
                            <Link className="nav-link" to="live-action-videos">
                              <div className="nav_icon">
                                <LazyLoad>
                                  <img
                                    alt=""
                                    className=""
                                    src="images/liveaction_icon.png"
                                  ></img>
                                </LazyLoad>
                              </div>{" "}
                              <div>
                                {" "}
                                <span>Live Action Video</span>{" "}
                                <p>
                                  {" "}
                                  Animated canvases giving life to ideas and
                                  making complex concepts engaging.
                                </p>
                              </div>
                            </Link>
</div> */}
                        </ul>
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <Link
                        className="nav-link navlink-drop p-0"
                        id="service_ddl"
                        data-toggle="dropdown"
                        to=""
                      >
                        Solutions
                        <ul className="dropdown-menu">
                          <h4 className="w-100">Video Use Case</h4>
                          <hr className=""></hr>

                          <div className="ddl_li">
                            <Link className="nav-link" to="/Agency">
                              <div className="nav_icon">
                                <img
                                  className=""
                                  src="images/explainervideos_icon.png"
                                ></img>
                              </div>
                              <div>
                                <span>Corporate Video</span>
                                <p>
                                  The attractive videos use colorful visuals to
                                  simplify storytelling.
                                </p>
                              </div>
                            </Link>
                          </div>
                          <div className="ddl_li">
                            <Link className="nav-link" to="educational-videos">
                              <div className="nav_icon">
                                <img
                                  className=""
                                  src="images/educationalvideos_icon.png"
                                ></img>
                              </div>
                              <div>
                                <span>E-Commerce</span>
                                <p>
                                  It makes learning fun again and gives viewers
                                  an experience like none other.
                                </p>
                              </div>
                            </Link>
                          </div>
                          <div className="ddl_li">
                            <Link className="nav-link" to="training-videos">
                              <div className="nav_icon">
                                <img
                                  className=""
                                  src="images/trainingvideos_icon.png"
                                ></img>
                              </div>
                              <div>
                                <span>Enterprise</span>
                                <p>
                                  A shortcut serving with all you need. The
                                  visual and auditory elements merge seamlessly.
                                </p>
                              </div>
                            </Link>
                          </div>
                          <div className="ddl_li">
                            <Link className="nav-link" to="brand-videos">
                              <div className="nav_icon">
                                <img
                                  className=""
                                  src="images/brandvideos_icon.png"
                                ></img>
                              </div>{" "}
                              <div>
                                {" "}
                                <span>SaaS & Tech</span>{" "}
                                <p>
                                  {" "}
                                  Combining technical expertise and creative
                                  vision to let you get closer to your goals.
                                </p>
                              </div>
                            </Link>
                          </div>
                          <div className="ddl_li">
                            <Link className="nav-link" to="social-media-videos">
                              <div className="nav_icon">
                                <img
                                  className=""
                                  src="images/socialmedia_icon.png"
                                ></img>
                              </div>{" "}
                              <div>
                                {" "}
                                <span>Start Up</span>{" "}
                                <p>
                                  {" "}
                                  Making your feed fun and extracting creativity
                                  from every pixel to resonate with your brand.
                                </p>
                              </div>
                            </Link>
                          </div>
                        </ul>
                      </Link>
                </li> */}

                    <li className="nav-item">
                      <Link className="nav-link" to="about-us">
                        About Us
                      </Link>
                    </li>

                    <li>
                      {/* <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Dropdown button
                    </button>
                    <ul className="dropdown-menu">
                    <Link className="nav-link" to="/explainer-videos"><div className='nav_icon'><img className='' src='images/explainervideos_icon.png'></img></div> <div> <span>Explainer Videos</span> <p> The attractive videos use colorful visuals to simplify storytelling and convey information effectively.</p></div></Link>
                    
                          <Link className="nav-link" to="educational-videos">
                            <div className='nav_icon'><img className='' src='images/explainervideos_icon.png'></img></div> <div> <span>Explainer Videos</span> <p> The attractive videos use colorful visuals to simplify storytelling and convey information effectively.</p></div></Link>
                        
                        
                          <Link className="nav-link" to="training-videos">
                            <div className='nav_icon'><img className='' src='images/explainervideos_icon.png'></img></div> <div> <span>Explainer Videos</span> <p> The attractive videos use colorful visuals to simplify storytelling and convey information effectively.</p></div></Link>
                        
                        
                          <Link className="nav-link" to="brand-videos">
                            <div className='nav_icon'><img className='' src='images/explainervideos_icon.png'></img></div> <div> <span>Explainer Videos</span> <p> The attractive videos use colorful visuals to simplify storytelling and convey information effectively.</p></div></Link>
                        
                        
                          <Link className="nav-link" to="social-media-videos">
                            <div className='nav_icon'><img className='' src='images/explainervideos_icon.png'></img></div> <div> <span>Explainer Videos</span> <p> The attractive videos use colorful visuals to simplify storytelling and convey information effectively.</p></div></Link>
                        
                        
                          <Link className="nav-link" to="3d-animation-videos">
                            <div className='nav_icon'><img className='' src='images/explainervideos_icon.png'></img></div> <div> <span>Explainer Videos</span> <p> The attractive videos use colorful visuals to simplify storytelling and convey information effectively.</p></div></Link>
                        
                        
                          <Link className="nav-link" to="motion-graphics">
                            <div className='nav_icon'><img className='' src='images/explainervideos_icon.png'></img></div> <div> <span>Explainer Videos</span> <p> The attractive videos use colorful visuals to simplify storytelling and convey information effectively.</p></div></Link>
                        
                        
                          <Link className="nav-link" to="whiteboard-animation">
                            <div className='nav_icon'><img className='' src='images/explainervideos_icon.png'></img></div> <div> <span>Explainer Videos</span> <p> The attractive videos use colorful visuals to simplify storytelling and convey information effectively.</p></div></Link>
                        
                    <Link className="nav-link" to="about">About Us</Link>
                    <Link className="nav-link" to="about">About Us</Link>
                      <li><a className="dropdown-item" href="about">Something else here</a></li>
                    </ul>
                    </div> */}
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="contact-us">
                        Contact
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="https://blog.videojeeves.com/"
                      >
                        Blog
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* ============ MOBILE NAVBAR STARTED HERE ============== */}

                <div className="mobile-nav">
                  <div className="nav-but-wrap">
                    <div className="menu-icon hover-target">
                      <span className="menu-icon__line menu-icon__line-left"></span>
                      <span className="menu-icon__line"></span>
                      <span className="menu-icon__line thirdLine"></span>
                    </div>
                  </div>

                  <div className="nav nav-fix-st">
                    <div className="nav__content">
                      <ul className="nav__list">
                        <li className="nav__list-item menu_dd_tab">
                          <Link className="hover-target arrow_down hover-target-close">
                            Services{" "}
                          </Link>
                          <i>
                            <FontAwesomeIcon
                              aria-hidden="true"
                              icon={faCaretDown}
                            />
                          </i>

                          <ul className="menu_dd" style={{ display: "none" }}>
                            {/* <li className='nav__list-item'><Link className="nav-link nav-link hover-target hover-target-close" to="2d-videos">2D Explainer Video</Link></li> */}
                            <li className="nav__list-item">
                              <Link
                                className="nav-link nav-link hover-target hover-target-close"
                                to="3d-animation-videos"
                              >
                                3D Explainer Video
                              </Link>
                            </li>
                            <li className="nav__list-item">
                              <Link
                                className="nav-link nav-link hover-target hover-target-close"
                                to="motion-graphics"
                              >
                                Motion Graphics Videos
                              </Link>
                            </li>
                            <li className="nav__list-item">
                              <Link
                                className="nav-link nav-link hover-target hover-target-close"
                                to="whiteboard-animation"
                              >
                                Whiteboard Animation
                              </Link>
                            </li>
                            {/* <li className='nav__list-item'><Link className="nav-link nav-link hover-target hover-target-close" to="cartoon-animation">Cartoon Animation</Link></li> */}
                            <li className="nav__list-item">
                              <Link
                                className="nav-link nav-link hover-target hover-target-close"
                                to="explainer-videos"
                              >
                                Explainer Videos
                              </Link>
                            </li>
                            <li className="nav__list-item">
                              <Link
                                className="nav-link nav-link hover-target hover-target-close"
                                to="educational-videos"
                              >
                                Educational Videos
                              </Link>
                            </li>
                            <li className="nav__list-item">
                              <Link
                                className="nav-link nav-link hover-target hover-target-close"
                                to="training-videos"
                              >
                                Training Videos
                              </Link>
                            </li>
                            <li className="nav__list-item">
                              <Link
                                className="nav-link nav-link hover-target hover-target-close"
                                to="social-media-videos"
                              >
                                Social Media Ads
                              </Link>
                            </li>
                            <li className="nav__list-item">
                              <Link
                                className="nav-link nav-link hover-target hover-target-close"
                                to="brand-videos"
                              >
                                Brand videos
                              </Link>
                            </li>
                            {/* <li className="nav__list-item">
                              <Link
                                className="nav-link nav-link hover-target hover-target-close"
                                to="live-action-videos"
                              >
                                Live action videos
                              </Link>
                  </li> */}
                            {/* <li className='nav__list-item'><Link className="nav-link nav-link hover-target hover-target-close" to="startup-videos">Startup videos</Link></li> */}
                            {/* <li  className='nav__list-item' ><Link className="nav-link nav-link hover-target hover-target-close" to="web-portal">Web Portal</Link></li> */}
                            {/* <li  className='nav__list-item' ><Link className="nav-link nav-link hover-target hover-target-close" to="ecommerce-solution">Ecommerce Solutions</Link></li> */}
                          </ul>
                        </li>
                        <li className="nav__list-item menu_dd_tab">
                          <Link
                            className="hover-target arrow_down hover-target-close"
                            to="about-us"
                          >
                            About Us{" "}
                          </Link>
                        </li>
                        <li className="nav__list-item">
                          <Link
                            to="contact-us"
                            className="hover-target hover-target-close"
                          >
                            Contact
                          </Link>
                        </li>
                        <li className="nav__list-item">
                          <Link
                            to="work"
                            className="hover-target hover-target-close"
                          >
                            Work
                          </Link>
                        </li>
                        <li className="nav__list-item">
                          <Link
                            to="https://blog.videojeeves.com/"
                            className="hover-target hover-target-close"
                          >
                            Blog
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="cta-btn header-btn">
                  <div className="link_wrapper">
                    {/* <Link href="#." className="get-btn popup-btn">Get Started</Link> */}
                    <a
                      href="javascript:void(Tawk_API.toggle())"
                      className="header_btn chats"
                    >
                      Talk to Us
                    </a>
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 268.832 268.832"
                      >
                        <path d="M265.17 125.577l-80-80c-4.88-4.88-12.796-4.88-17.677 0-4.882 4.882-4.882 12.796 0 17.678l58.66 58.66H12.5c-6.903 0-12.5 5.598-12.5 12.5 0 6.903 5.597 12.5 12.5 12.5h213.654l-58.66 58.662c-4.88 4.882-4.88 12.796 0 17.678 2.44 2.44 5.64 3.66 8.84 3.66s6.398-1.22 8.84-3.66l79.997-80c4.883-4.882 4.883-12.796 0-17.678z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}
