import React from "react";
import { LightgalleryItem } from "react-lightgallery";
import CounterSec from "../components/includes/CounterSec";
import FaqSec from "../components/includes/FaqSec";
import CtaSec from "../components/includes/CtaSec";
import { useEffect } from "react";
import Aos from "aos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function MainService() {
  useEffect(() => {
    if (window.jQuery(window).width() < 767) {
      window.jQuery(".res_slider").slick({
        dots: true,
      });
      window.jQuery(".res_slider2").slick();
    }
    Aos.init();
  });
  return (
    <>
      <section className="m_sec1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h1 className="banner_h1">
                We leverage video Across your marketing
              </h1>
              <p className="defaultp">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                nec finibus enim, nec blandit erat. Praesent ac turpis risus.
                Fusce mollis felis lacus, sed hendrerit nisi pulvinar et.{" "}
              </p>
              <div className="h_btn a_btn"></div>
              <div className="s1_form">
                <form action="https://www.videojeeves.com/mail/" method="POST">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="em"
                      id=""
                      aria-describedby="helpId"
                      placeholder="Enter Your Email"
                      required
                    />
                    <input
                      type="hidden"
                      className="form-control"
                      name="pn"
                      id=""
                      aria-describedby="helpId"
                      value="Subscribe Email"
                    />
                    <input
                      type="hidden"
                      className="form-control"
                      name="cn"
                      id=""
                      aria-describedby="helpId"
                      value="Subscribe Email"
                    />
                    <input
                      type="hidden"
                      className="form-control"
                      name="msg"
                      id=""
                      aria-describedby="helpId"
                      value="Subscribe Email"
                    />
                    <button type="submit">
                      Get an Estimate
                      <input
                        type="hidden"
                        id="lead_area_popup"
                        name="lead_area"
                        value="for $119"
                      />
                      <input
                        type="hidden"
                        id="lead_org_price"
                        name="lead_org_price"
                        value="19"
                      />
                      <input type="hidden" name="send" value="1" />
                      <input
                        type="hidden"
                        name="service_id"
                        value="5791"
                        className="service_id"
                      />
                      <input type="hidden" name="lb_source" value="" />
                      <input type="hidden" name="lb_source_cat" value="" />
                      <input type="hidden" name="lb_source_nam" value="" />
                      <input type="hidden" name="lb_source_ema" value="" />
                      <input type="hidden" name="lb_source_con" value="" />
                      <input type="hidden" name="lb_source_pho" value="" />
                      <input type="hidden" name="lb_source_off" value="" />
                      <input type="hidden" name="fullpageurl" value="" />
                      <input type="hidden" name="pageurl" value="" />
                      <input type="hidden" name="ip2loc_ip" value="" />
                      <input type="hidden" name="ip2loc_isp" value="" />
                      <input type="hidden" name="ip2loc_org" value="" />
                      <input type="hidden" name="ip2loc_country" value="" />
                      <input type="hidden" name="ip2loc_region" value="" />
                    </button>
                  </div>
                </form>
              </div>
              <div className="s1_achievement">
                <div className="a_achievement">
                  <h3>1500+</h3>
                  <p>
                    Video <br /> projects
                  </p>
                </div>
                <div className="a_achievement">
                  <h3>1.4 million+</h3>
                  <p>
                    Video <br /> Views
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <img
                alt="Main Service Page"
                className="img-fluid"
                src="images/mainservice-page/s1right.png"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="m_sec2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-10 col-md-12 col-sm-12">
              <h2 className="defaulth2">Exploring Real-World</h2>
              <p className="defaultp">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                sit amet porta felis, sed commodo ante. Donec eu nisl
                vestibulum, hendrerit risus non, mattis purus. Proin rhoncus est
                at orci consequat lobortis. Nam dignissim,
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process">
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Concept & Story Creation</span>
                </li>
                <li className="">
                  <img alt="Arrow " src="images/mainservice-page/arrow.svg" />
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Scriptwriting</span>
                </li>
                <li className="">
                  <img alt="Arrow " src="images/mainservice-page/arrow.svg" />
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Storyboarding</span>
                </li>
                <li className="">
                  <img alt="Arrow " src="images/mainservice-page/arrow.svg" />
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Style & Visual design</span>
                </li>
                <li className="">
                  <img alt="Arrow" src="images/mainservice-page/arrow.svg" />
                </li>
                <li className="m_s2_box">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Voiceover & Sound design</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process_gif res_slider2">
                <li className="">
                  <img
                    alt="Process"
                    src="images/mainservice-page/process_1.gif"
                  />
                </li>
                <li className="">
                  <img
                    alt="Process"
                    src="images/mainservice-page/process_2.gif"
                  />
                </li>
                <li className="">
                  <img
                    alt="Process"
                    src="images/mainservice-page/process_3.gif"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="m_sec3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div className="s3_capable">
                <img alt="Widget" src="images/mainservice-page/s3_widget.png" />
                <h2 className="defaulth2">Capabilities</h2>
                <p className="defaultp">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  sit amet porta felis, sed commodo ante. Donec eu nisl
                  vestibulum, hendrerit risus non, mattis purus. Proin rhoncus
                  est at orci consequat lobortis. Nam dignissim,
                </p>
              </div>
            </div>
            <div className="col-lg-9 col-md-6 col-sm-12">
              <ul className="list-unstyled capabilities_ul res_slider">
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </li>
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing sit amet,
                    consectetur
                  </p>
                </li>
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </li>
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </li>
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </li>
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </li>
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </li>
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </li>
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="m_sec4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">Exploring Real-World</h2>
              <p className="defaultp">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                sit amet porta felis, sed commodo ante. Donec eu nisl
                vestibulum, hendrerit risus non, mattis purus. Proin rhoncus est
                at orci consequat lobortis. Nam dignissim,
              </p>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="images/mainservice-page/s4img2.png">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                {/* <LightgalleryItem></LightgalleryItem> */}

                <img
                  alt="Section 4 img1"
                  className="img-fluid"
                  src="images/mainservice-page/s4img1.png"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="images/mainservice-page/s4img3.png">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt="Section 4 img2"
                  className="img-fluid"
                  src="images/mainservice-page/s4img2.png"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="images/mainservice-page/s4img4.png">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>

                <img
                  alt="Section 4 img3"
                  className="img-fluid"
                  src="images/mainservice-page/s4img3.png"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="images/mainservice-page/s4img5.png">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt=""
                  className="img-fluid"
                  src="images/mainservice-page/s4img4.png"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="images/mainservice-page/s4img6.png">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt=""
                  className="img-fluid"
                  src="images/mainservice-page/s4img5.png"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="images/mainservice-page/s4img1.png">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt="Section 4 img6"
                  className="img-fluid"
                  src="images/mainservice-page/s4img6.png"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="m_sec5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div className="s3_capable">
                <img
                  alt="section 5 icon"
                  src="images/mainservice-page/s5icon.png"
                />
                <h2 className="defaulth2">Types of Video</h2>
                <p className="defaultp">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  sit amet porta felis, sed commodo ante. Donec eu nisl
                  vestibulum, hendrerit risus non, mattis purus. Proin rhoncus
                  est at orci consequat lobortis. Nam dignissim,Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit. Donec sit amet
                  porta felis, sed commodo ante. Donec eu nisl vestibulum.
                </p>
              </div>
            </div>
            <div className="col-lg-9 col-md-6 col-sm-12">
              <ul className="list-unstyled capabilities_ul res_slider">
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec sit amet porta felis, sed commodo ante. Donec eu nisl
                    vestibulum,
                  </p>
                </li>
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec sit amet porta felis, sed commodo ante. Donec eu nisl
                    vestibulum,
                  </p>
                </li>
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec sit amet porta felis, sed commodo ante. Donec eu nisl
                    vestibulum,
                  </p>
                </li>
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec sit amet porta felis, sed commodo ante. Donec eu nisl
                    vestibulum,
                  </p>
                </li>
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec sit amet porta felis, sed commodo ante. Donec eu nisl
                    vestibulum,
                  </p>
                </li>
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec sit amet porta felis, sed commodo ante. Donec eu nisl
                    vestibulum,
                  </p>
                </li>
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec sit amet porta felis, sed commodo ante. Donec eu nisl
                    vestibulum,
                  </p>
                </li>
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec sit amet porta felis, sed commodo ante. Donec eu nisl
                    vestibulum,
                  </p>
                </li>
                <li className="capabilities_box">
                  <h3>Capabilities</h3>
                  <p className="defaultp">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec sit amet porta felis, sed commodo ante. Donec eu nisl
                    vestibulum,
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <CounterSec></CounterSec>
      <FaqSec></FaqSec>
      <CtaSec></CtaSec>
    </>
  );
}
