import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import clutchexplainervideo from "../assets/img/clutchexplainervideo.png";
// import clutchtrainingvideo from "../assets/img/clutchtrainingvideo.png";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazy-load";

const Awards = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <section className="awardSect py-5">
      <div className="container mx-auto">
        <div className="row align-items-center">
          <div className="col-lg-4 w-full text-lg-start text-center mb-lg-0 mb-5 ">
            <h3 className="awrdsHeading">Awards & Recognition</h3>
          </div>
          <div className="col-lg-8 w-full">
            <Slider {...settings}>
              <div className="sliderItemHW">
                <Link
                  to="https://clutch.co/profile/videojeeves?utm_source=clutch_top_company_badge&utm_medium=image_embed#highlights"
                  target={"blank"}
                >
                  <LazyLoad>
                    <img
                      src="images/clutchtrainingvideo.png"
                      alt="Clutch.co Training Video"
                    />
                  </LazyLoad>
                </Link>
              </div>
              <div className="sliderItemHW">
                <Link
                  to="https://upcity.com/local-marketing-agencies/profiles/videojeeves/locations/videojeeves"
                  target={"blank"}
                >
                  <LazyLoad>
                    <img
                      src="images/topvideoproduction.png"
                      alt="top video production"
                    />
                  </LazyLoad>
                </Link>
              </div>
              <div className="sliderItemHW">
                <Link
                  to="https://clutch.co/profile/videojeeves?utm_source=clutch_top_company_badge&utm_medium=image_embed#highlights"
                  target={"blank"}
                >
                  <LazyLoad>
                    <img
                      src="images/clutchexplainervideo.png"
                      alt="Clutch.co Explainer Video"
                    />
                  </LazyLoad>
                </Link>
              </div>
              <div className="sliderItemHW">
                <Link
                  to="https://agencyspotter.com/top/video-production-agencies"
                  target={"blank"}
                >
                  <LazyLoad>
                    <img
                      alt="agency spotter"
                      src="images/agencyspotter.png"
                    ></img>
                  </LazyLoad>
                </Link>
              </div>
              <div className="sliderItemHW">
                <Link
                  to="https://www.sortlist.com/agency/video-jeeves"
                  target={"blank"}
                >
                  <LazyLoad>
                    <img
                      alt="Sortlist Agency"
                      src="images/sortlistbadge.png"
                    ></img>
                  </LazyLoad>
                </Link>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Awards;
