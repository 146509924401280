import React from "react";
import Aos from "aos";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { LightgalleryItem } from "react-lightgallery";
import Slider from "react-slick";
import CtaSec from "../components/includes/CtaSec";
import { Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

export default function BrandVideos() {
  document.title = "Captivating Brand Videos | Beyond Words";
  // document.getElementsByTagName("META")[2].content =
  //   "Experience our ideas in a visually appealing way. Our impactful brand videos will unveil the story and let you witness our commitment to excellence.";
  var Portfolio_Slider = {
    slidesToShow: 1,
    infinite: true,
    dots: true,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "120px",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "50px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    window.jQuery(document).ready(function () {
      window.jQuery(".myBtn").click(function () {
        var dots = window.jQuery(".dots");
        var moreText = window.jQuery(".more");
        var btnText = window.jQuery(".myBtn");

        if (dots.css("display") === "none") {
          dots.css("display", "inline");
          btnText.html("Read more");
          moreText.css("display", "none");
        } else {
          dots.css("display", "none");
          btnText.html("Read less");
          moreText.css("display", "inline");
        }
      });
    });
    if (window.jQuery(window).width() < 767) {
      window.jQuery(".res_slider").slick({
        dots: true,
      });
      window.jQuery(".res_slider2").slick();
    }

    Aos.init();
  });
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.videojeeves.com/brand-videos" />
        <meta
          name="description"
          content="Experience our ideas in a visually appealing way. Our impactful brand videos will unveil the story and let you witness our commitment to excellence."
        />
      </Helmet>
      <section className="inner_sec1 videos">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h1 className="banner_h1">
                Brand Videos That Steal The Spotlight
              </h1>
              <p className="defaultp">
                Our animation-driven videos will steal the show!
              </p>
              <p className="defaultp">
                We wanted to know what people preferred to see the most and
                found that around 95% of B2B buyers move forward with their
                purchase after watching a video. <br />
                Our video production service offers binge-worthy brand videos so
                you can captivate and engage your target.
              </p>
              <div className="h_btn">
                <Link
                  data-aos="fade-up"
                  data-aos-duration="8600"
                  className="popup-btn white_btn"
                  to="/Contact-us"
                >
                  get a quote
                </Link>
                <a
                  href="javascript:void(Tawk_API.toggle())"
                  className="pop-btn black_btn chats"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Live Chat
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 banner_play">
              <LightgalleryItem src="https://www.youtube.com/watch?v=P2hv3JyxLwY&list=PLFr89EPS-7DX5lkHOLrV9VdGCs7QUG0Hc&index=5">
                <img className="play-btn-st" src="images/play.png" alt="" />
              </LightgalleryItem>
              <img
                alt=""
                className="img-fluid"
                src="images/brandvideos-page/s1right.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                alt=""
                className="img-fluid"
                src="images/brandvideos-page/1.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2"> Lights, Camera, Animation</h2>
              <p className="defaultp">
                Together, we can create something truly unforgettable!
              </p>
              <p className="defaultp">
                We are pros at crafting scripts and bringing the magic to your
                screens. <br />
                Our video production services use the art of crafting compelling
                stories and passing down valuable information. Animated brand
                videos prove why still images are not the way forward for
                modern-age branding.
                {/* <span className="dots">...</span>
                <span className="more">
                  
                  <br />
                  Let’s go dynamic with our animated brand videos. We are here
                  proving why still images are boring. Each frame is a stroke
                  that paints the canvas of your mind with vivid, moving
                  stories. We don't just animate; we come up with emotions,
                  tales of wonder, and dreams that dance before your eyes.
                  <br />
                  We are a pro at reading scripts and turning them into screen
                  magic. Our team believes the script is like butter so we turn
                  them into cinematic popcorn.
  </span> */}
              </p>
              {/* <button className="myBtn read_more_btn">
                Read more <i className="fa-solid fa-angles-right"></i>
</button> */}
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec3 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                Taking audiovisual production to the next level
              </h2>
              <p className="defaultp">
                Video Jeeves rewrites the rulebook of audiovisual production and
                sets new standards of excellence.
                <br /> Your brand video can feature anything you wish for!
                <br />
                Effective brand video production gives you the necessary edge
                over your competitors. By combining technical expertise and a
                creative vision to build a video content strategy, you will get
                closer to your goals.
                {/*<span className="dots">...</span>
                <span className="more">
                  
                  <br />
                  Let’s go dynamic with our animated brand videos. We are here
                  proving why still images are boring. Each frame is a stroke
                  that paints the canvas of your mind with vivid, moving
                  stories. We don't just animate; we come up with emotions,
                  tales of wonder, and dreams that dance before your eyes.
                  <br />
                  We are a pro at reading scripts and turning them into screen
                  magic. Our team believes the script is like butter so we turn
                  them into cinematic popcorn.
</span> */}
              </p>
              {/*   <button className="myBtn read_more_btn">
                Read more <i className="fa-solid fa-angles-right"></i>
                  </button> */}
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                alt=""
                className="img-fluid"
                src="images/brandvideos-page/2.png"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="m_sec4 inner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">
                Taking stories from script to screens
              </h2>
              <p className="defaultp">
                From scripting to screening, we execute it all fluently!
              </p>
              <p className="defaultp">
                A brand video could be a one-shot wonder or series of epic
                proportions impacting the future of content marketing.
              </p>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/AO5Gnv6ZyNg?si=pS88T4h6BhNep8r9">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/brandvideos-page/s4img1.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>2Hire</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://youtu.be/semVzZE8AHw?si=sz1fgXDqTbs8pDTV">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/brandvideos-page/s4img2.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Coolest Custom Printing</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=EAAkVBT229k">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/brandvideos-page/s4img3.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Compliance Tech</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=6PbO3QO-RHA">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/brandvideos-page/s4img4.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Logo Animation</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=TRfsOG-7t_0">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/brandvideos-page/s4img5.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Snowbird Investor</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="m_s4_slide">
                <LightgalleryItem src="https://www.youtube.com/watch?v=_yXbRAcaqPo&list=PLFr89EPS-7DU3dEsDS1wE2hjGkHwPjyJt&index=5">
                  <img className="play-btn-st" src="images/play.png" alt="" />

                  <img
                    className="img-fluid"
                    src="images/brandvideos-page/s4img6.png"
                  ></img>
                  <div className="s4_overlay">
                    <h4>Venture Capital</h4>
                    <p>Play Video</p>
                  </div>
                </LightgalleryItem>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_sec2 zig_zag">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                className="img-fluid"
                src="images/brandvideos-page/3.png"
              ></img>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h2 className="defaulth2">Bringing your Brand to Life</h2>
              <p className="defaultp">
                Video Jeeves follows a thorough process that incorporates your
                input into the final product. <br />
                We can spice up your videos without missing out on the details.
                Our video production team shapes how your business looks, feels,
                and sounds.
                <br />
                So, hold onto your seats, because we're not just featuring your
                imagination; we're crafting your wildest fantasies into reality.
                {/* <span className="dots">...</span>
                <span className="more">
                  
                  <br />
                  Let’s go dynamic with our animated brand videos. We are here
                  proving why still images are boring. Each frame is a stroke
                  that paints the canvas of your mind with vivid, moving
                  stories. We don't just animate; we come up with emotions,
                  tales of wonder, and dreams that dance before your eyes.
                  <br />
                  We are a pro at reading scripts and turning them into screen
                  magic. Our team believes the script is like butter so we turn
                  them into cinematic popcorn.
                </span> */}
              </p>
              {/* <button className="myBtn read_more_btn">
                Read more <i className="fa-solid fa-angles-right"></i>
              </button> */}
            </div>
          </div>
        </div>
      </section>
      {/* <section className='inner_sec3 zig_zag'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <h2 className='defaulth2'>Exploring Real-World</h2>
                    <p className='defaultp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit tortor sit amet lectus luctus auctor. Quisque hendrerit pellentesque urna, eu eleifend dolor ornare ac. Nullam ornare tincidunt erat, ut laoreet purus condimentum ut. Fusce feugiat ipsum nisi, sit amet commodo odio imperdiet eu. Mauris luctus consequat arcu quis vestibulum. Aenean laoreet, dui eu rhoncus placerat, dolor turpis euismod lorem, quis eleifend tortor eros id diam. Morbi a tincidunt ex. Cras augue dolor, molestie ut dapibus id, interdum vel felis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit tortor sit amet lectus luctus auctor. Quisque hendrerit pellentesque urna, eu eleifend dolor ornare ac. Nullam ornare tincidunt erat, ut laoreet purus condimentum ut. Fusce feugiat ipsum nisi, sit amet commodo odio imperdiet eu. Mauris luctus consequat arcu quis vestibulum. Aenean laoreet, dui eu rhoncus placerat, dolor turpis euismod lorem, quis eleifend tortor eros id diam. Morbi a tincidunt ex. Cras augue dolor, molestie ut dapibus id, interdum vel felis.</p>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                    <img className='img-fluid' src='images/innerservice-page/s3right.png'></img>
                </div>
            </div>
        </div>
    </section> */}
      <section className="m_sec2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="defaulth2">Stirring innovation into ideas</h2>
              <p className="defaultp">
                Step into our world, where imagination takes center stage!
                <br />
                We're the modern-day wizards coming up with animated wonders.
                Our creative team blends ideas, stirs innovation, and voila! Now
                your vision has transformed into a reality!
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process">
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Concept & Story Creation</span>
                </li>
                <li className="">
                  <img alt="" src="images/mainservice-page/arrow.svg"></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Scriptwriting</span>
                </li>
                <li className="">
                  <img alt="" src="images/mainservice-page/arrow.svg"></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Storyboarding</span>
                </li>
                <li className="">
                  <img alt="" src="images/mainservice-page/arrow.svg"></img>
                </li>
                <li className="m_s2_box arrow">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Style & Visual design</span>
                </li>
                <li className="">
                  <img alt="" src="images/mainservice-page/arrow.svg"></img>
                </li>
                <li className="m_s2_box">
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Voiceover & Sound design</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <ul className="list-unstyled process_gif res_slider2">
                <li className="">
                  <img alt="" src="images/mainservice-page/process_1.gif"></img>
                </li>
                <li className="">
                  <img alt="" src="images/mainservice-page/process_2.gif"></img>
                </li>
                <li className="">
                  <img alt="" src="images/mainservice-page/process_3.gif"></img>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="inner_portfolio">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h2 className="defaulth2">Our Portfolio</h2>
              <p className="defaultp">
                We’re a brand video production company that offers quality
                services for your business. Take a look at our portfolio!
              </p>
              <Link className="popup-btn white_btn button-86" to="/Contact-us">
                Get started
              </Link>
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="portfolio_slider">
                <Slider {...Portfolio_Slider}>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://youtu.be/AO5Gnv6ZyNg?si=pS88T4h6BhNep8r9">
                      <img
                        alt=""
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      ></img>
                    </LightgalleryItem>
                    <img
                      alt=""
                      className="img-fluid"
                      src="images/brandvideos-page/port_img1.png"
                    ></img>
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://www.youtube.com/watch?v=EAAkVBT229k">
                      <img
                        alt=""
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      ></img>
                    </LightgalleryItem>
                    <img
                      alt=""
                      className="img-fluid"
                      src="images/brandvideos-page/port_img2.png"
                    ></img>
                  </div>
                  <div className="m_s4_slide">
                    <LightgalleryItem src="https://www.youtube.com/watch?v=6PbO3QO-RHA">
                      <img
                        alt=""
                        className="img-fluid play-btn-st"
                        src="images/play.png"
                      ></img>
                    </LightgalleryItem>
                    <img
                      alt=""
                      className="img-fluid"
                      src="images/brandvideos-page/port_img3.png"
                    ></img>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec2 inner_serv">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="h_s2_content">
                <h2
                  className="defaulth2"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  Explore Our Services
                </h2>
                <p className="defaultp">We have done so much more.</p>
              </div>
            </div>
          </div>
          <div className="row res_slider">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <LightgalleryItem src="https://www.youtube.com/watch?v=HZ4BG6xZyHU">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt=""
                  className="img-fluid"
                  src="images/brandvideos-page/forgiveandforget.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/educational-videos">
                      Explore Educational Videos
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1100"
              >
                <LightgalleryItem src="https://youtu.be/qgSPyEdZMhU">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt=""
                  className="img-fluid"
                  src="images/brandvideos-page/voipfone.png"
                ></img>
                <div className="s2_box_data">
                  <h5>
                    <Link to="/social-media-videos">
                      Explore Social Media Ads
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="h_s2box"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <LightgalleryItem src="https://www.youtube.com/watch?v=lqCRl2Ki-WE">
                  <img className="play-btn-st" src="images/play.png" alt="" />
                </LightgalleryItem>
                <img
                  alt=""
                  className="img-fluid"
                  src="images/brandvideos-page/yashsolutions.png"
                ></img>
                <div className="s2_box_data">
                  {/* <h5>Startup Videos </h5> */}
                  <h5>
                    <Link to="/startup-videos">Explore Startup Videos</Link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="h_sec7">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2
                className="defaulth2"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                FAQs
              </h2>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    What is it like to work with Video Jeeves?
                  </Accordion.Header>
                  <Accordion.Body>
                    From the journey of telling a story to structuring and
                    brainstorming ideas, we aim to leave a lasting influence on
                    audiences. Our team considers every aspect of your business
                    and implements creative ideas.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Why is brand video production important for businesses?
                  </Accordion.Header>
                  <Accordion.Body>
                    A brand video represents a brand's identity and conveys
                    messages effectively. Such videos are highly engaging and
                    more memorable than text or images. We believe that by
                    investing in a brand video, you can gain an edge over your
                    competitors.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    How long does it take to produce a brand video?
                  </Accordion.Header>
                  <Accordion.Body>
                    It depends if the video is in 2D or 3D. Typically, it takes
                    around 2 weeks to produce a one-minute 2D video. However,
                    the timeline for both 2D & 3D varies depending on multiple
                    factors. If you want to get an estimate, feel free to ask
                    over chat or email us.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Do I need to provide a script for branded video production?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, you can provide your input or collaborate with our team
                    to write a script that aligns with your brand's message.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <CtaSec></CtaSec>
    </>
  );
}
